import { allStatesJson } from '../Data/allStatesJson'
import { nationalCountiesJson } from '../Data/nationalCountiesJson'
import { nationalDistrictsJson } from './nationalDistrictsJson'

export const mapsNationalData = {
    mapZoom: 4,
    reportMapZoom: 3,
    //mapBoxCenter: [-100.901, 39.0586],
    mapBoxCenter: [-98.5795, 39.8282], 
    //mapBoxCenterReport: [-107.901, 41.0586],// Update?
    mapBoxCenterReport: [-98.5795, 39.8282],
    //mapMaxBounds: [[125.155536, 5.714165], [-53.157750, 73.253393]],
    //mapMaxBoundsReport: [[110.155536, 0.714165], [-53.157750, 73.253393]],
    //reportMapWidth: '60vw',
    'Federal Cases': { // info based on location filter
        'Location ': {
            'State Prosecuted': {
                dataVar: 'nationalStateProsecuted',
                sourceVar: 'state_prosecuted',
                source2Var: 'state_prosecuted',
                totalDataVar: 'nationalTotal',
                mapJson: allStatesJson,
                mapLocVar: 'STATELOCATION',
                mapLoc2Var: 'STATELOCATION',
                hoverTitleVar: 'NAME',
                mapFillOpacity: 0.9,
                mapFillColors: [
                    [1, "#e9f6fc"],
                    [20, "#93d6f1"],
                    [40, "#28ade3"],
                    [50, "#186788"],
                    [60, "#0c3344"],
                    [100, "#000000"],
                ],
                fillColorProp: 'SCREENINGS',
                fillOutlineColor: '#28ADE3',
                mapInfoBox: {
                    title: 'Federal Cases Prosecuted',
                    info: [
                        {
                            label: 'Total Cases:',
                            dataVar: 'nationalTotal',
                            sourceVar: 'count',
                            style: { fontSize: '1.25em', lineHeight: '1.5em', display: 'block', margin: '0em 0em 0.8em !important' },
                        },
                        {
                            label: 'Minor Sex Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'minorsextraf',
                            calcType: 'addEachObj',
                            style: { fontSize: '1em', display: 'block', lineHeight: '1.5em', margin: '0em 0em 0.5em' },
                        },
                        {
                            label: 'Adult Sex Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'adultsextraf',
                            calcType: 'addEachObj',
                            style: { fontSize: '1em', display: 'block', lineHeight: '1.5em', margin: '0em 0em 0.5em' },
                        },
                        {
                            label: 'Labor Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'labor',
                            calcType: 'addEachObj',
                            style: { fontSize: '1em', display: 'block', lineHeight: '1.5em', margin: '0em 0em 0.5em' },
                        },
                        {
                            label: 'Both Sex and Labor Trafficking:',
                            dataVar: 'nationalStateProsecuted',
                            sourceVar: 'both',
                            calcType: 'addEachObj',
                            style: { fontSize: '1em', display: 'block', lineHeight: '1.5em' },
                        },
                    ]
                },
                hoverInfoBoxVars: [
                    'minorsextraf', 'adultsextraf', 'labor', 'both'
                ]
            },


            'District Prosecuted': {
                dataVar: 'districtsTotals',
                sourceVar: 'district_prosecuted',
                // totalDataVar: '',
                mapLocVar: 'DISTRICT',
                // mapLoc2Var: 'CIRCUIT',
                mapJson: nationalDistrictsJson,
                mapFillOpacity: [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1,
                    0.9
                ],
                mapFillColors:
                    ['interpolate-hcl', ['linear'],
                        ['get', 'CIRCUIT'],
                        0, '#1957CD',
                        1, '#0F347B',
                        2, "#46ADE3",
                        3, "#1895A7",
                        4, "#A5E3ED",
                        5, "#44CEA5",
                        6, "#297C63",
                        7, "#AEE0C6",
                        8, "#532C95",
                        9, "#9E9BF0",
                        10, "#6F0C4E",
                        11, "#B2127C",
                    ],
                fillColorProp: 'CIRCUIT',
                fillOutlineColor: '#000',
                loc2MapOutlines: allStatesJson, // show state outlineslines too
                stateLinesColor: "#000",
            },


            'Trafficking Locations': {
                dataVar: 'nationalTraffLocations',
                sourceVar: 'county',
                source2Var: 'statelocation',
                totalDataVar: 'nationalTraffLoTotal',
                mapJson: nationalCountiesJson,
                mapLocVar: 'NAME',
                mapLoc2Var: 'STATELOCATION',
                noPercent: true,
                mapFillOpacity: 0.9,
                mapFillColors: [
                // They want a better color distinction between 0 and 1
                    [0, "#e9f6fc"],
                    [1, "#a2dbf2"],
                    [2, "#63c9f2"],
                    [5, "#28ade3"],
                    [10, "#186788"],
                    [20, "#0c3344"],
                    [30, "#000000"],
                    
                    // [1, "#e9f6fc"],
                    // [2, "#93d6f1"],
                    // [5, "#28ade3"],
                    // [10, "#186788"],
                    // [20, "#0c3344"],
                    // [30, "#000000"],
                ],
                fillColorProp: 'SCREENINGS',
                fillOutlineColor: '#28ADE3',
                loc2MapOutlines: allStatesJson, // show state outlineslines too
                stateLinesColor: "#186788",
            }
        },
        mapInfo: 
            <div>
                <p className='margin0'><span className='fontWeight700'>Data Source:</span> Human Trafficking Data</p>
                <p className='margin0'><span className='fontWeight700'>Date:</span> 2000-Present Day</p>
                <p>
                Federal Human Trafficking Case data has been collected by Allies Against Slavery in partnership with Dr. Vanessa Bouché. Data is based on federal human trafficking cases prosecuted nationally, and includes information about case level variables, trafficking locations, defendant and victim characteristics, and criminal methods.
                <br/><br/>
                The State Prosecuted view shows the distribution of prosecution data across the United States. If a case has been transferred to another state or territory, the case falls under the location where sentencing occurred. The map card and hovers show this prosecution data broken down by trafficking type.
                <br/><br/>
                The District Prosecuted view is the district in which the case was prosecuted. If a case has been transferred to another district, the case falls under the district where sentencing occurred. To view the number of cases prosecuted by district based on a specific trafficking type, select an option from the Trafficking Type filter.
                <br/><br/>
                The Trafficking Locations view shows the number of trafficking locations by county or parish. A trafficking location refers to specific businesses, addresses, cities and counties/parishes where victims were recruited, transported, obtained, retained, harbored and exploited. This view does not include all cases, since some case documents do not specify where in the state trafficking occurred. Those cases are coded with "Unlisted" trafficking locations. To view the distribution of trafficking locations based on a specific trafficking type, select an option from the Trafficking Type filter.
              </p>
            </div>,
    },
    'State Policy': { // info based on location filter
        'Data': {
            dataVar: 'statePolicies',
            sourceVar: 'state',
            totalDataVar: 'state_policy',
            mapJson: allStatesJson,
            mapLocVar: 'NAME',
            hoverTitleVar: 'NAME',
            mapFillOpacity: 0.9,
            mapFillColors: [
                [0, "#e9f6fc"],
                [4, "#93d6f1"],
                [8, "#28ade3"],
                [12, "#186788"],
                [16, "#0c3344"],
                [19, "#000000"],
            ],
            mapInfoBox: {
                title: 'Policies Passed',
                info: [
                    {
                        label: 'Affirmative Defense',
                        dataVar: 'policyType',
                        sourceVar: 'affirm_def',
                        calcType: 'addEachObj',
                        style: { display: 'block', margin: '1em 0em' },
                    },
                    {
                        label: 'Mistake of Age',
                        dataVar: 'policyType',
                        sourceVar: 'age',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Asset Forfeiture',
                        dataVar: 'policyType',
                        sourceVar: 'asset_forf',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Civil Action',
                        dataVar: 'policyType',
                        sourceVar: 'civ_act',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Trauma-informed CSES Screening',
                        dataVar: 'policyType',
                        sourceVar: 'csec',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Education for Students',
                        dataVar: 'policyType',
                        sourceVar: 'edu_stud',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Hotline Posting',
                        dataVar: 'policyType',
                        sourceVar: 'hot_post',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Increased Investigative Tools for Law Enforcement',
                        dataVar: 'policyType',
                        sourceVar: 'inc_it',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Buyers',
                        dataVar: 'policyType',
                        sourceVar: 'john',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Low Burden for Minor',
                        dataVar: 'low_burden',
                        sourceVar: 'age',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Reporting',
                        dataVar: 'policyType',
                        sourceVar: 'rep',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Regulation/Licensing Restrictions',
                        dataVar: 'policyType',
                        sourceVar: 'reg',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Restitution',
                        dataVar: 'policyType',
                        sourceVar: 'restitu',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Safe Harbor',
                        dataVar: 'policyType',
                        sourceVar: 'safe_harb',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Survivor Assistance',
                        dataVar: 'policyType',
                        sourceVar: 'sur_assist',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Task Force',
                        dataVar: 'policyType',
                        sourceVar: 'tf',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Training for Educators',
                        dataVar: 'policyType',
                        sourceVar: 'edu_train',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Training for Law Enforcement',
                        dataVar: 'policyType',
                        sourceVar: 'train',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                    {
                        label: 'Vacating Prior Convictions',
                        dataVar: 'policyType',
                        sourceVar: 'vac_prior',
                        calcType: 'addEachObj',
                        style: { display: 'block' },
                    },
                ]
            },
            hoverInfoBoxVars: [
                'affirm_def', 'age'
            ]
        },
        mapInfo: 
            <div>
                <p className='margin0'><span className='fontWeight700'>Data Source:</span> State Policy Data</p>
                <p className='margin0 marginB14'><span className='fontWeight700'>Date:</span> 2003-present</p>
                <p>
                State Policy Data is originally from Dr. Vanessa Bouche, and was comprehensively updated by the Bush School of Government & Public Policy at Texas A&M University in 2024.
                <br/><br/>
                This map shows the total number of investigated human trafficking policies enacted by each state, out of nineteen total policies, listed below:
                <ul>
                    <li>Affirmative Defense</li>
                    <li>Asset Forfeiture</li>
                    <li>Buyers</li>
                    <li>Civil Action</li>
                    <li>Education for Students</li>
                    <li>Hotline Posting</li>
                    <li>Increased Investigative Tools for Law Enforcement</li>
                    <li>Low Burden for Minor</li>
                    <li>Mistake of Age</li>
                    <li>Regulation/Licensing Restrictions</li>
                    <li>Reporting</li>
                    <li>Restitution</li>
                    <li>Safe Harbor</li>
                    <li>Survivor Assistance</li>
                    <li>Task Force</li>
                    <li>Trauma-informed CSES Screening</li>
                    <li>Training for Educators</li>
                    <li>Training for Law Enforcement</li>
                    <li>Vacating Prior Convictions</li>
                </ul>
                Users can scroll on the map view to view Hawaii and Alaska. The map view shows enactment status and not effective status of the applicable legislation, meaning that if the map shows a state as having a certain policy, that policy has been enacted by the state, but may not necessarily be effective yet. Data in this map is based exclusively on each state's codified law, and does not take into account executive orders. Full descriptions of each policy can be found in the State Policy Data Glossary.
              </p>
            </div>,
    },
}