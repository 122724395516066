export const screeningsgraphdata = {
  // Screening information
  allScreenings: {
    'TX': [
      {
        variable: 'wcc',
        name: 'WCCC',
        
      },
      {
        variable: 'tjjd',
        name: 'TJJD',
        
      },
      {
        variable: 'dfps',
        name: 'DFPS',
        
      },
      {
        variable: 'lighthouse',
        name: 'Lighthouse',
        
      },
    ],
    'LA': [
      {
        variable: 'wcc',
        name: 'WCCC',
        
      },
      {
        variable: 'lighthouse',
        name: 'Lighthouse',
        
      },
    ]
  },
  screeningsIndividuals: [
    {
      name: "Individuals Screened",
      variable: "distinct",
    }, {
      name: "Screenings",
      variable: "screenings",
    }
  ],
  concernLevel: [
    {
      variable: 'Clear Concern',
      name: 'Clear Concern',
      color: '#D94547',
    },
    {
      variable: 'Possible Concern',
      name: 'Possible Concern',
      color: '#F1B254',
    },
    {
      variable: 'No Concern',
      name: 'No Concern',
      color: '#5DB076'
    },
  ],

  //Age
  ageData: [
    {
      variable: "0",
      name: "0",
      
    },
    {
      variable: "1",
      name: "1",
      
    },
    {
      variable: "2",
      name: "2",
      
    },
    {
      variable: "3",
      name: "3",
      
    },
    {
      variable: "4",
      name: "4",
      
    },
    {
      variable: "5",
      name: "5",
      
    },
    {
      variable: "6",
      name: "6",
      
    },
    {
      variable: "7",
      name: "7",
      
    },
    {
      variable: "8",
      name: "8",
      
    },
    {
      variable: "9",
      name: "9",
      
    },
    {
      variable: "10",
      name: "10",
      
    },
    {
      variable: "11",
      name: "11",
      
    },
    {
      variable: "12",
      name: "12",
      
    },
    {
      variable: "13",
      name: "13",
      
    },
    {
      variable: "14",
      name: "14",
      
    },
    {
      variable: "15",
      name: "15",
      
    },
    {
      variable: "16",
      name: "16",
      
    },
    {
      variable: "17",
      name: "17",
      
    },
    {
      variable: "18",
      name: "18",
      
    },
    {
      variable: "19",
      name: "19",
      
    },
    {
      variable: "20",
      name: "20",
      
    },
    {
      variable: "21",
      name: "21",
      
    },
    {
      variable: "22",
      name: "22",
      
    },
    {
      variable: "23",
      name: "23",
      
    },
    {
      variable: "24",
      name: "24",
      
    },
  ],
  //AgeAll 25+
  ageGroupData: [
    {
      variable: "25-29",
      name: "25-29",
      
    },
    {
      variable: "30-39",
      name: "30-39",
      
    },
    {
      variable: "40-49",
      name: "40-49",
      
    },
    {
      variable: "50-59",
      name: "50-59",
      
    },
    {
      variable: "60-69",
      name: "60-69",
      
    },
    {
      variable: "70-79",
      name: "70-79",
      
    },
    {
      variable: "80+",
      name: "80-89",
      
    },
    {
      variable: "Unknown",
      name: "Unknown",
      
    },
  ],
  // Race
  raceData: [
    {
      variable: "African American or Black",
      name: "African American or Black",
      
    },
    {
      variable: "American Indian or Alaska Native",
      name: "American Indian or Alaska Native",
      
    },
    {
      variable: "Asian",
      name: "Asian",
      
    },
    {
      variable: "Middle Eastern or North African",
      name: "Middle Eastern or North African",
      
    },
    {
      variable: "Multiracial",
      name: "Multiracial",
      
    },
    {
      variable: "Native Hawaiian or Other Pacific Islander",
      name: "Native Hawaiian or Other Pacific Islander",
      
    },
    {
      variable: "Other",
      name: "Other",
      
    },
    {
      variable: "Unknown",
      name: "Unknown",
      
    },
    {
      variable: "White or Caucasian",
      name: "White or Caucasian",
       // 
    },
  ],
  // Ethnicity
  ethnicityData: [
    {
      variable: "Hispanic or Latino",
      name: "Hispanic or Latino",
      
    },
    {
      variable: "Not Hispanic or Latino",
      name: "Not Hispanic or Latino",
      
    },
    {
      variable: "Unknown",
      name: "Unknown",
      
    },
  ],
  //Gender
  genderData: [
    {
      variable: "Female",
      name: "Female",
      
    },
    {
      variable: "Male",
      name: "Male",
      
    },
    {
      variable: "Other",
      name: "Other",
      
    },
    {
      variable: "Unknown",
      name: "Unknown",
      
    },
  ],
  educationConcern: [
    {  variable: 'College degree', name: 'College/advanced degree'},
    {  variable: 'Assoc degree', name: "Associate's degree" },
    {  variable: 'Vocation training', name: 'Trade/technical/vocational training' },
    {  variable: 'Some college', name: 'Some college credit/no degree' },
    {  variable: 'Diploma/GED', name: 'High school diploma/GED' },
    {  variable: 'Some HS', name: 'Some high school/ no diploma' },
    {  variable: '12', name: '12th Grade' },
    {  variable: '11', name: '11th Grade' },
    {  variable: '10', name: '10th Grade' },
    {  variable: '9', name: '9th Grade' },
    {  variable: '8', name: '8th Grade' },
    {  variable: '7', name: '7th Grade' },
    {  variable: '6', name: '6th Grade' },
    {  variable: '5', name: '5th Grade' },
    {  variable: '4', name: '4th Grade' },
    {  variable: '3', name: '3rd Grade' },
    {  variable: '2', name: '2nd grade' },
    {  variable: '1', name: '1st Grade' },
    {  variable: 'K', name: 'Kindergarten' },
    {  variable: 'Pre-K', name: 'Pre-kindergarten' }],
}