import React, { useEffect } from "react";
import { statepolicydata } from "../../GraphComponents/GraphVariables/StatePolicyData";

import SingleCard from "../../../../Components/Card";
import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import { GroupedBarVariableLegend } from "../../GraphComponents/GroupedBarVariableLegend";
import ScatterplotChart  from "../../GraphComponents/Scatterplot";
import { SingleBarDottedGraph } from "../../GraphComponents/SingleBarDottedGraph";
import GridChart  from "../../GraphComponents/GridChart";

// import CasesByYearTable from './Tables/CasesByYearTable'
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'


export default function StatePolicy(props) {
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [graphStackedData, setGraphStackedData] = React.useState([]);

  useEffect(() => {
    console.log(props.data)
    setGraphData(props.data);
    setGraphStackedData(props.data);
  }, [props.data]);

  return (
    <div>
      <div>
        <SingleCard
          width="100%"
          id="Policies Passed Human Trafficking Policies Passed"
          title="Policies Passed  >  Human Trafficking Policies Passed"
          graph={
            <StackedBarVariableXGraph
              data={props.data.policies}
              xaxis="Time"
              yaxis="Total Policies"
              keys={statepolicydata.policies}
              type="cc"
              fontSize="true"
            />
          }
          table={
            <TableWithTotal
              id="Human Trafficking Policies Passed"
              data={props.data.policies ?? []}
              headers={['Year', 'Prevention Policies', 'Protection Policies', 'Prosecution Policies', 'Total Policies Passed']}
              columnKeys={['xaxis', 'prevention', 'protection', 'prosecution', 'total']}
              customTotalRowData={props.data.policies[props.data.policies.length - 1]}
            />
          }
          definitions={[["", 'This chart displays the total number of Prevention Policies, Protection Policies, and Prosecution Policies that have been passed by states, based on selected filters. Full descriptions of each policy can be found in the State Policy Data Glossary. The Location and Policy filters apply to this chart.'],
          ["", 'Prevention Policies aim to prevent human trafficking (such as through education and training) and include the following policies:'],
          ["", <ul><li>Education for Students</li> <li>Regulation/Licensing Restrictions</li> <li>Reporting</li> <li>Task Force</li> <li>Training for Educators</li> <li>Training for Law Enforcement</li></ul>],
          ["", 'Protection Policies aim to protect victims of human trafficking and include the following policies:'],
          ["", <ul><li>Affirmative Defense</li> <li>Civil Action</li> <li>Hotline Posting</li> <li>Safe Harbor</li> <li>Survivor Assistance</li> <li>Trauma-informed CSES Screening</li> <li>Vacating Prior Convictions</li></ul>],
          ["", 'Prosecution Policies are related to the prosecution of or investigation of human trafficking crimes and include the following policies:'],
          ["", <ul><li>Asset Forfeiture</li> <li>Buyers Policy</li> <li>Increased Investigative Tools for Law Enforcement</li> <li>Low Burden of Proof for Minors</li> <li>Mistake of Age</li> <li>Restitution</li></ul>]
          ]}
          addToReports={props.addToReportPage}
        />

        <SingleCard
          width="100%"
          id="Policies Passed Net Policies Passed by Year"
          title="Policies Passed  >  Net Policies Passed by Year"
          graph={
            <StackedBarVariableXGraph
              data={props.data.netPolicies ?? []}
              xaxis="Year"
              yaxis="Number of Policies"
              keys={statepolicydata.netPolicies}
              type="cc"
              fontSize="true"
            />
          }
          table={
            <TableWithTotal
              noTotalRow
              id="Net Policies Passed by Year"
              data={props.data.netPolicies ?? []}
              headers={['Year', 'Prevention', 'Protection', 'Prosecution', 'Total']}
              columnKeys={['xaxis', 'prevention', 'protection', 'prosecution', 'total']}
            />
          }
          definitions={[["", "This chart shows the net total of state-level human trafficking policies passed each year, subject to applied filters. The default chart view shows net state-level human trafficking policies passed in all states as of the most recent year's data. All filters apply to this chart."]]}
          addToReports={props.addToReportPage}
        />

        <SingleCard
          width="100%"
          id="Policies Passed Sentencing Requirements"
          title="Policies Passed  >  Sentencing Requirements"
          graph={
            <GroupedBarVariableLegend
              data={props.data.sentencingRequirements ?? []}
              keys={statepolicydata.sentencingRequirements}
              xaxis="Sentence Category"
              yaxis="Sentence Length in Years"
              multipleYMaxValues={['min_sex_minor1', 'min_sex_minor2', 'max_sex_minor1', 'max_sex_minor2', 'min_sex_ad1', 'min_sex_ad2', 'max_sex_ad1', 'max_sex_ad2', 'min_lab_min1', 'min_lab_min2', 'max_lab_min1', 'max_lab_min2', 'min_lab_ad1', 'min_lab_ad2', 'max_lab_ad1', 'max_lab_ad2']}
              handleStateClick={props.handleStateClick}
              activeCategory={props.statePolicyStateSelection}
              statePolicyStateSelection={props.statePolicyStateSelection}
            />
          }
          table={
            <TableWithTotal
              noTotalRow
              id="Sentencing Requirements"
              data={props.data.sentencingAllTableData ?? []}
            headers={['State', 'Sentencing Category', 'Years']}
            columnKeys={['state', 'category', 'years']}
            />
          }
          definitions={[["", "This chart shows the state-level criminal sentencing requirements for an individual state as compared to the average state-level criminal sentencing requirements for all states, subject to applied filters. The default chart view shows criminal sentencing requirements as of the most recent year's data. Only the Time filter applies to this chart."]]}
          addToReports={props.addToReportPage}
        />

        <SingleCard
          width="100%"
          id="Policies Passed Policy Prevalence and Adoption Time"
          title="Policies Passed  >  Policy Prevalence and Adoption Time"
          graph={
            <ScatterplotChart
              data={props.data.policyAdoption ?? []}
              value="variable"
              xaxisValue="yearAverage"
              yaxisValue="totalStates"
              specificColors={true}
              xaxis="Average Years to Pass After First Policy Passed"
              yaxis="Number of States"
              legend={true}
              legendCategories={['prevention', 'protection', 'prosecution']}
            />
          }
          table={
            <TableWithTotal
              noTotalRow
              id="Policy Prevalence and Adoption Time"
              data={props.data.policyAdoption ?? []}
              headers={['Policy', 'Average Years to Pass Policy', 'Number of States']}
              columnKeys={['name', 'yearAverage', 'totalStates']}
            />
          }
          definitions={[["", 'This chart displays each policy based on two factors: the total number of states that have adopted it and the average time in years it took for states to adopt the policy after the first state adopted the policy, subject to selected filters. Policies plotted further left were more quickly adopted by states on average, whereas policies plotted further right were more slowly adopted by states on average. Policies higher on the chart have been passed by a higher total number of states, whereas those lower down were passed by fewer states.'],
          ["", 'For states that have passed, repealed, and then re-passed a policy, averages are calculated based on the most recent policy passage year. The default chart view is based on the most recent year’s data. All filters apply to this chart. If a single state is selected in the Location filter, the x-axis shows how many years that state took to pass the policy after the first state adopted it. See below for a list of policy abbreviations and corresponding full policy names.'],
          ["", <ul>
            <li>AffirmDef: Affirmative Defense</li>
            <li>Age: Mistake of Age</li> 
            <li>AssetForf: Asset Forfeiture</li>
            <li>Buyers: Buyers</li> 
            <li>CivAct: Civil Action</li> 
            <li>CSEC: CSEC Screening</li> 
            <li>EduTrain: Training for Educators</li>
            <li>Hotline: Hotline Posting</li> 
            <li>LETrain: Training for Law Enforcement</li> 
            <li>LETools: Increased Investigative Tools for Law Enforcement</li> <li>LowBurden: Low Burden for Minors</li> 
            <li>Reg: Regulations and Licensing Restrictions</li>
            <li>Report: Reporting</li> 
            <li>Restitu: Restitution</li>
            <li>SafeHarb: Safe Harbor: Immunity</li> 
            <li>StudentEd: Education for Students</li> 
            <li>SurAssist: Survivor Assistance</li> 
            <li>TF: Task Force</li> 
            <li>Vacatur: Vacating Prior Convictions</li></ul>]
            ]}
          addToReports={props.addToReportPage}
        />

        <SingleCard
          width="100%"
          id="Policies Passed Policies Passed and Average Lag Time"
          title="Policies Passed  >  Policies Passed and Average Lag Time"
          graph={
            <GridChart
              data={props.data.policyLagTime ?? []}
              value="variable"
              xaxisValue="averageTime"
              yaxisValue="totalPolicies"
              //specificColors={true}
              xaxis="Average Lag Time to Pass Policy After First State (Years)"
              yaxis="Number of Policies"
              // legend={true}
              // legendCategories={['prevention', 'protection', 'prosecution']}
            />
          }
          table={
            <TableWithTotal
              noTotalRow
              id="Policies Passed and Average Lag Time"
              data={props.data.policyLagTime ?? []}
              headers={['Policy', 'Average Years to Pass Policy', 'Number of States']}
              //headers={['State', 'Number of Policies', 'Average Lag Time']}
              columnKeys={['name', 'yearAverage', 'totalStates']}
            />
          }
          definitions={[["", 'This chart plots each state based on two factors: the total number of state-level human trafficking policies passed and the average lag time (in years) it took for the state to pass those policies after the first state passed them. States plotted further left were quicker to adopt policies on average, whereas states plotted further right were slower to adopt policies on average. States plotted higher have a higher total number of policies, whereas states plotted lower have a lower total number of policies.'],
          ["", 'In states where a policy was passed, then repealed, and then passed again, averages are calculated based on the most recent policy passage year. The default chart view plots states based on the most recent year’s data. All filters apply to this chart.']]}
          addToReports={props.addToReportPage}
        />


        <SingleCard
          width="100%"
          id="Policies Passed Policy Prevalence"
          title="Policies Passed  >  Policy Prevalence"
          graph={
            <HorizontalBarChart
              data={props.data.policyPrevalence}
              value="title"
              xaxis="Number of States"
              yaxis="Policy"
              specificColors={true}
              statePolicyPercent={true}
              legend={true}
              legendCategories={['prevention', 'protection', 'prosecution']}
              yLabelsWidthMax={true}
            />
          }
          table={
            <TableWithTotal
              id="State Policy Policy Prevalence"
              data={props.data.policyPrevalence ?? []}
              headers={['Policies Present', 'Total']}
              columnKeys={['title', 'total']}
              descending={true}
              totalKey='total'
              rightAlignTotal={true}
            />
          }
          definitions={[["", "This chart displays the prevalence of state-level human trafficking policies across all states, based on applicable filters. The default chart view shows policies present in states as of the most recent year's data. This means that for the applicable year, the policies were either passed or already effective. All filters apply to this chart."]]}
          addToReports={props.addToReportPage}
        />

        <SingleCard
          width="100%"
          id="Policies Passed Total Policies Passed"
          title="Policies Passed  >  Total Policies Passed"
          graph={
            <SingleBarDottedGraph
              data={props.data.totalPolicies ?? []}
              keys={statepolicydata.netPolicies}
              xaxis="Policy Type"
              yaxis="Number of Policies"
              type="cc"
              smalltext
            />
          }
          table={
            <TableWithTotal
              id="Total Policies Passed"
              data={props.data.totalPolicies ?? []}
              headers={['Policy Type', 'Total']}
              columnKeys={['xaxis', 'value']}
            />
          }
          definitions={[["", "This chart shows the total number of state-level human trafficking policies passed for each policy category (Prevention, Protection, and Prosecution), subject to applied filters. The grey outlines above the bars show the maximum number of state-level human trafficking policies possible in each policy category. The default view shows the totals for all states as of the most recent year. All filters apply to this chart."]]}
          addToReports={props.addToReportPage}
        />
      </div>
    </div>
  );
}
