import React, { useEffect, Fragment } from "react";
import { publicsafetydata } from "../../GraphComponents/GraphVariables/PublicSafety";
import { publicsafetymockdata } from "./PublicSafetyData";
import SingleCard from "../../../../Components/Card";
//import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";
//import { GroupedBarVariableXGraph } from "../../GraphComponents/GroupedBarVariableXAxis";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
import PublicSafetyTable from "./Tables/PublicSafetyTable";
import PublicSafetyHorizontalTable from "./Tables/PublicSafetyHorizontalTable";
import HorizontalBarChart from "../../GraphComponents/HorizontalBarChart";
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'
// import TableForYearGraphs from '../../../../Components/ReusableTables/TableForYearGraphs'
import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";

export default function PublicSafety(props) {
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [graphStackedData, setGraphStackedData] = React.useState([]);

  useEffect(() => {
    setGraphData(props.data);
    setGraphStackedData(props.data);
    console.log(props.data)
  }, [props.data]);

  // const addToReports = (data) => {
  //   props.addToReportPage(data);
  // };

  const renderAllCharts = () => {
    return (
      <div>
        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Year of Arrest (by Agency)"
          title="Case and Agency Information  >  Cases by Year of Arrest (by Agency)"
          definitions={[
            [
              "Cases by Year of Arrest (by Agency)",
              "Cases include the locations of the arresting, prosecuting and court agencies. Cases are plotted by year based on the date of arrest, and are counted in the Arresting Agency bar for that year, with the corresponding Prosecuting and/or Court Agency counted in the same year.  For example, if a case has the date of arrest on September 1, 2010, that case is counted in 2010 in the Arresting Agency bar, with its Prosecuting and Court agencies (if known) plotted in the same year. Some arrests do not proceed to prosecution or court charges. Reporting of Arresting and Court agencies is required, but not Prosecuting agencies. Consequently, some years have fewer Prosecuting agencies than Court agencies. Given the time lag for cases to move from arrest to prosecution to court, recent years show a steeper decline from Arresting to Prosecuting and Court agencies. For example, a case with an arrest in 2019 may not have proceeded to prosecution or court, so it will only be counted in the Arresting Agency bar for that year. ",
            ],
          ]}
          graph={
            <GroupedBarVariableXGraph
              data={props.data.casesYearAgency}
              keys={publicsafetydata.casesYearAgency}
              xaxis="Year of Arrest"
              yaxis="Number of Cases"
              trendLine={true}
              trendLineLegend="Total Cases"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.casesYearAgency}
              keys={publicsafetydata.casesYearAgency}
              id="Cases by Year of Arrest (by Agency)"
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Year of Arrest"
          title="Case and Agency Information  >  Cases by Year of Arrest"
          definitions={[
            [
              "Cases by Year of Arrest",
              "Cases are plotted by year based on the date of arrest. Cases are organized by the penal code chapter of the offense at arrest.",
            ],
          ]}
          graph={
            <StackedBarVariableXGraph
              data={props.data.casesYearPenal}
              keys={publicsafetydata.casesYearPenal}
              xaxis="Year of Arrest"
              yaxis="Number of Cases"
              trendLine={true}
              trendLineLegend="Total Cases"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.casesYearPenal}
              keys={publicsafetydata.casesYearPenal}
              id="Cases by Year of Arrest"
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Year of Arrest (Top Ten Offenses)"
          title="Case and Agency Information  >  Cases by Year of Arrest (Top Ten Offenses)"
          definitions={[
            [
              "Cases by Year of Arrest (Top Ten Offenses)",
              "Cases are plotted by year based on the date of arrest. The chart is based on the top ten offenses over all cases. The “Offenses” filter is not applicable for this chart.",
            ],
          ]}
          graph={
            <StackedBarVariableXGraph
              data={props.data.casesYearOffense}
              keys={publicsafetydata.casesYearOffense}
              xaxis="Year of Arrest"
              yaxis="Number of Cases"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.casesYearOffense}
              keys={publicsafetydata.casesYearOffense}
              id="Cases by Year of Arrest (Top Ten Offenses)"
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* Horizontal Bar Chart */}
        <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Arresting Agency"
          title="Case and Agency Information  >  Cases by Arresting Agency"
          definitions={[
            [
              "Cases by Arresting Agency",
              "The top ten arresting agencies are ranked by number of cases, based on selected Offense filters.",
            ],
          ]}
          graph={
            <HorizontalBarChart
              data={props.data.agencyOria}
              value="agency_oria_agency"
              xaxis="Number of Cases"
              yaxis="Top Ten Agencies"
              smalltext={true}
            />
          }
          table={
            <PublicSafetyHorizontalTable
              data={props.data.agencyOria}
              reverseOrder={true}
            />
          }
          addToReports={props.addToReportPage}
        />

        {/* Horizontal Bar Chart */}
        {/* <SingleCard
          width="100%"
          id="Case and Agency Information Cases by Agency (Prosecuting Agency (ORIP))"
          title="Case and Agency Information  >  Cases by Agency (Prosecuting Agency (ORIP))"
          definitions={[
            [
              "Cases by Agency (Prosecuting Agency (ORIP))",
              "The top ten prosecuting agencies are ranked by number of cases, based on selected Offense filters.",
            ],
          ]}
          graph={
            <HorizontalBarChart
              data={props.data.agencyOrip}
              value="agency_orip_agency"
              xaxis="Number of Cases"
              yaxis="Top Ten Agencies"
              smalltext={true}
            />
          }
          table={
            <PublicSafetyHorizontalTable
              data={props.data.agencyOrip}
              reverseOrder={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {props.stateLocation === 'LA' &&
          // {/* Horizontal Bar Chart */}
          <SingleCard
            width="100%"
            id="Case and Agency Information Cases by Court Agency"
            title="Case and Agency Information  >  Cases by Court Agency"
            definitions={[
              [
                "Cases by Court Agency",
                "The top ten court agencies are ranked by number of cases, based on selected Offense filters.",
              ],
            ]}
            graph={
              <HorizontalBarChart
                data={props.data.agencyOric}
                value="agency_oric_agency"
                xaxis="Number of Cases"
                yaxis="Top Ten Agencies"
                smalltext={true}
              />
            }
            table={
              <PublicSafetyHorizontalTable
                data={props.data.agencyOric}
                reverseOrder={true}
              />
            }
            addToReports={props.addToReportPage}
          />
        }

        <div>
          <SingleCard
            // className={classes.card}
            width="100%"
            title="Case and Agency Information  >  Arrest Cases by Location and Population"
            id="Case and Agency Information Arrest Cases by Location and Population"
            graph={
              <HorizontalBarChart
                percentOfTotal={props.data.arrestCasesByLocationPop}
                data={props.data.arrestCasesByLocationPop?.slice(0, 10)} // get top 10 counties for the chart
                value="city"
                xaxis="Number of Cases per 100,000"
                yaxis="Locations"
              />
            }
            definitions={[["", "This chart shows the top ten locations for arrest cases per 100,000 population per location in descending order, based on selected filters. The percent is the number of cases by location per 100,000 population out of all cases per 100,000 population. The table shows cases for all locations per 100,000 population."]]}
            table={
              <TableWithTotal
                id="Case and Agency Information Arrest Cases by Location and Population"
                name='Arrest Cases by Location and Population'
                data={props.data.arrestCasesByLocationPop}
                descending
                toFixed={2}
                tableTotal={props.data.arrestCasesByLocationPopTotal}
                headers={['Location', 'Number of Cases by Location and Population']}
                columnKeys={['city', 'total']}
                totalKey='total'
              />
            }
            addToReports={props.addToReportPage}
          />
        </div>

        {props.stateLocation === 'LA' &&
          <SingleCard
            // className={classes.card}
            width="100%"
            id="Case and Agency Information Cases by Year of Arrest (by Offense Group)"
            title="Case and Agency Information  >  Cases by Year of Arrest (by Offense Group)"
            definitions={[["", "This chart shows the number of arrest cases over time grouped by Offense groups. The Arrest Year filter does not apply to this chart. All other filters apply."]]}
            graph={
              <StackedBarVariableXGraph
                data={props.data.casesYearOffense}
                keys={props.stateLocation === 'TX' ? publicsafetydata.casesYearOffenseTX : publicsafetydata.casesYearOffenseLA}
                xaxis="Years"
                yaxis="Number of Cases"
              // differentYaxisMaxVars={props.stateLocation === 'TX' ? ['offense1', 'offense2','offense3','offense4', 'offense5', 'offense6', 'offense7', 'offense8', 'offense9', 'offense10'] : ['offense1', 'offense2','offense3','offense4']}
              />
            }
            table={
              <TableWithTotal
                id="Cases by Year of Arrest (by Offense Group)"
                name='Cases by Year of Arrest (by Offense Group)'
                data={props.data.casesYearOffense}
                toFixed={0}
                ascending
                headers={props.stateLocation === 'TX' ? ['Year', 'Indecency with a Child - Sexual Contact/Exposure of Genitals with Intent to Gratify', 'Aggravated Sexual Assault Child', 'Prostitution (before 2015)', 'Sexual Assault of a Child', 'Prostitution 1+ Convictions (before 2015)', 'Sexual Assault', 'Prostitution - Seller', 'Possession of Child Pornography with 1+ Previous Conviction', 'Continuous Sex Abuse of a Child', 'Prostitution 1+ Convictions - Seller', 'Total'] : ['Year', 'Trafficking of Persons', 'Internet Crimes Against Children', 'Sexual Assault Against Minors', 'Prostitution Offenses', 'Total']}
                columnKeys={props.stateLocation === 'TX' ? ['xaxis', 'offense1', 'offense2', 'offense3', 'offense4', 'offense5', 'offense6', 'offense7', 'offense8', 'offense9', 'offense10', 'total'] : ['xaxis', 'offense1', 'offense2', 'offense3', 'offense4', 'total']}
                totalKey='total'
                horizontalTotal
              />
            }
            addToReports={props.addToReportPage}
          />
        }

        {/* Horizontal Bar Chart */}
        {/* <SingleCard
            width="100%"
            id="Case and Agency Information Cases by Year of Arrest (by Agency)"
            title="Case and Agency Information  >  Cases by Year of Arrest (by Agency)"
            definitions={[
              [
                "Cases by Year of Arrest (by Agency)",
                "Cases include the locations of the arresting, prosecuting and court agencies. Cases are plotted by year based on the date of arrest, and are counted in the Arresting Agency bar for that year, with the corresponding Prosecuting and/or Court Agency counted in the same year.  For example, if a case has the date of arrest on September 1, 2010, that case is counted in 2010 in the Arresting Agency bar, with its Prosecuting and Court agencies (if known) plotted in the same year. Some arrests do not proceed to prosecution or court charges. Reporting of Arresting and Court agencies is required, but not Prosecuting agencies. Consequently, some years have fewer Prosecuting agencies than Court agencies. Given the time lag for cases to move from arrest to prosecution to court, recent years show a steeper decline from Arresting to Prosecuting and Court agencies. For example, a case with an arrest in 2019 may not have proceeded to prosecution or court, so it will only be counted in the Arresting Agency bar for that year. ",
              ],
            ]}
            graph={
              <GroupedBarVariableXGraph
                data={props.data.casesYearAgency}
                keys={publicsafetydata.casesYearAgency}
                xaxis="Year of Arrest"
                yaxis="Number of Cases"
                trendLine={true}
                trendLineLegend="Total Cases"
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.casesYearAgency}
                keys={publicsafetydata.casesYearAgency}
                id="Cases by Year of Arrest (by Agency)"
              />
            }
            addToReports={props.addToReportPage}
          />

          <SingleCard
            width="100%"
            id="Case and Agency Information Cases by Year of Arrest (by Penal Code Chapter)"
            title="Case and Agency Information  >  Cases by Year of Arrest (by Penal Code Chapter)"
            definitions={[
              [
                "Cases by Year of Arrest (by Penal Code Chapter)",
                "Cases are plotted by year based on the date of arrest. Cases are organized by the penal code chapter of the offense at arrest.",
              ],
            ]}
            graph={
              <StackedBarVariableXGraph
                data={props.data.casesYearPenal}
                keys={publicsafetydata.casesYearPenal}
                xaxis="Year of Arrest"
                yaxis="Number of Cases"
                trendLine={true}
                trendLineLegend="Total Cases"
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.casesYearPenal}
                keys={publicsafetydata.casesYearPenal}
                id="Cases by Year of Arrest (by Penal Code Chapter)"
              />
            }
            addToReports={props.addToReportPage}
          />

        {/* Demographics */}
        {/* <SingleCard
          width="100%"
          id="Demographics Age (by Penal Code Chapters)"
          title="Demographics  >  Age (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXGraph
              data={props.data.agePenalCode}
              keys={publicsafetydata.agePenalCode}
              xaxis="Age at Offense"
              yaxis="Number of Individuals"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.agePenalCode}
              keys={publicsafetydata.agePenalCode}
              id="Age (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        <SingleCard
          width="100%"
          id="Offender Demographics Age"
          title="Offender Demographics  >  Age"
          graph={
            <SingleBarGraph
              data={
                props.data.ageOffense
                  ? props.data.ageOffense
                  : publicsafetymockdata.ageOffense
              }
              keys={publicsafetydata.ageOffenseCode}
              xaxis="Age at Offense"
              yaxis="Number of Individuals"
              type="cc"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.ageOffense}
              keys={publicsafetydata.ageOffenseCode}
              id="Age"
              single={true}
            />
          }
          addToReports={props.addToReportPage}
        />

        {/* <SingleCard
          width="100%"
          id="Demographics Race (by Penal Code Chapters)"
          title="Demographics  >  Race (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXGraph
              data={props.data.racePenalCode}
              keys={publicsafetydata.racePenalCode}
              xaxis="Race"
              yaxis="Number of Individuals"
              smalltext={true}
              wrapText={true}
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.racePenalCode}
              keys={publicsafetydata.racePenalCode}
              id="Race (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {props.stateLocation === 'LA' &&
          <SingleCard
            width="100%"
            id="Offender Demographics Race"
            title="Offender Demographics  >  Race"
            graph={
              <SingleBarGraph
                data={
                  props.data.raceOffense
                    ? props.data.raceOffense
                    : publicsafetymockdata.raceOffense
                }
                keys={publicsafetydata.raceOffenseCode}
                xaxis="Race"
                yaxis="Number of Individuals"
                type="cc"
                smalltext={true}
              />
            }
            table={
              <PublicSafetyTable
                data={props.data.raceOffense}
                keys={publicsafetydata.raceOffenseCode}
                id="Race"
                single={true}
              />
            }
            addToReports={props.addToReportPage}
          />
        }

        {/* <SingleCard
          width="100%"
          id="Demographics Ethnicity (by Penal Code Chapters)"
          title="Demographics  >  Ethnicity (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXGraph
              data={props.data.ethnicityPenalCode}
              keys={publicsafetydata.ethnicityPenalCode}
              xaxis="Ethnicity"
              yaxis="Number of Individuals"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.ethnicityPenalCode}
              keys={publicsafetydata.ethnicityPenalCode}
              id="Ethnicity (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Demographics Ethnicity (by Offense)"
          title="Demographics  >  Ethnicity (by Offense)"
          graph={
            <SingleBarGraph
              data={
                props.data.ethnicityOffense
                  ? props.data.ethnicityOffense
                  : publicsafetymockdata.ethnicityOffense
              }
              keys={publicsafetydata.ethnicityOffenseCode}
              xaxis="Ethnicity"
              yaxis="Number of Individuals"
              type="cc"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.ethnicityOffense}
              keys={publicsafetydata.ethnicityOffenseCode}
              id="Ethnicity (by Offense)"
              single={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}
{/* 
        <SingleCard
          width="100%"
          id="Demographics Gender (by Penal Code Chapters)"
          title="Demographics  >  Gender (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXGraph
              data={props.data.genderPenalCode}
              keys={publicsafetydata.genderPenalCode}
              xaxis="Gender"
              yaxis="Number of Individuals"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.genderPenalCode}
              keys={publicsafetydata.genderPenalCode}
              id="Gender (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        <SingleCard
          width="100%"
          id="Offender Demographics Gender"
          title="Offender Demographics  >  Gender"
          graph={
            <SingleBarGraph
              data={props.data.genderOffense ?? []}
              keys={publicsafetydata.genderOffenseCode}
              xaxis="Gender"
              yaxis="Number of Individuals"
              type="cc"
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.genderOffense}
              keys={publicsafetydata.genderOffenseCode}
              id="Gender"
              single={true}
            />
          }
          addToReports={props.addToReportPage}
        />

        {/* <SingleCard
          width="100%"
          id="Demographics Citizenship (by Penal Code Chapters)"
          title="Demographics  >  Citizenship (by Penal Code Chapters)"
          graph={
            <StackedBarVariableXGraph
              data={props.data.citizenshipPenalCode}
              keys={publicsafetydata.citizenshipPenalCode}
              xaxis="Citizenship"
              yaxis="Number of Individuals"
              smalltext={true}
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.citizenshipPenalCode}
              keys={publicsafetydata.citizenshipPenalCode}
              id="Citizenship (by Penal Code Chapters)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}

        {/* <SingleCard
          width="100%"
          id="Demographics Citizenship (by Offense)"
          title="Demographics  >  Citizenship (by Offense)"
          graph={
            <StackedBarVariableXGraph
              data={props.data.citizenshipOffense}
              keys={publicsafetydata.citizenshipOffenseCode}
              xaxis="Citizenship"
              yaxis="Number of Individuals"
              smalltext={true}
              noLegend={true}
            />
          }
          table={
            <PublicSafetyTable
              data={props.data.citizenshipOffense}
              keys={publicsafetydata.citizenshipOffenseCode}
              id="Citizenship (by Offense)"
              penalCode={true}
            />
          }
          addToReports={props.addToReportPage}
        /> */}
      </div>
    );
  }

  const renderSingleChart = () => {
    const charts = renderAllCharts();
    const singleCards = charts.props.children.props.children;

    const index = singleCards.findIndex((card) => card.props.id === props.chartId);
    if (index <= -1) {
      return <Fragment></Fragment>;
    }

    const elementToRender = singleCards[index];
    return <SingleCard
        {...elementToRender.props}
        title={props.chartTitle || props.title}
        description={props.chartInsight || ""}
        shouldUseNewStyles={true}
    />;
  }

  return (
      props.chartId
        ? renderSingleChart()
        : renderAllCharts()
  );
}
