export const publicsafetydata = {
    casesYearAgency: [ 
        { 
            variable : "oria" ,
            name: "Arresting Agency (ORIA)",
            
        },
        { 
            variable : "orip",
            name: "Prosecuting Agency (ORIP)",
            
        }, 
        {
            variable : "oric" ,
            name: "Court Agency (ORIC)",
            
        },  
    ],
    casesYearPenal: [ 
        { 
            variable : "chapter20" ,
            name: "Chapter 20A: Trafficking of Persons",
            
        },
        { 
            variable : "chapter21",
            name: "Chapter 21: Sexual Offenses",
            
        }, 
        {
            variable : "chapter22" ,
            name: "Chapter 22: Assaultive Offenses",
            
        },  
        { 
            variable : "chapter25" ,
            name: "Chapter 25: Offenses Against the Family",
            
        },
        { 
            variable : "chapter33" ,
            name: "Chapter 33: Computer Crimes",
            
        },  
        {
            variable : "chapter43" ,
            name: "Chapter 43: Public Indecency",
            
        }
    ], 
    casesYearOffenseTX : [
        { 
            variable : "offense1" ,
            name: "Indecency with a Child - Sexual Contact/Exposure of Genitals with Intent to Gratify",
            
        },
        { 
            variable : "offense2",
            name: "Aggravated Sexual Assault Child",
            
        }, 
        {
            variable : "offense3" ,
            name: "Prostitution (before 2015)",
            
        },  
        { 
            variable : "offense4" ,
            name: "Sexual Assault of a Child",
            
        },
        { 
            variable : "offense5" ,
            name: "Prostitution 1+ Convictions (before 2015)",
            
        },  
        {
            variable : "offense6" ,
            name: "Sexual Assault",
            
        },  
        {
            variable : "offense7" ,
            name: "Prostitution - Seller",
            
        },  
        {
            variable : "offense8" ,
            name: "Possession of Child Pornography with 1+ Previous Conviction",
            
        },  
        {
            variable : "offense9" ,
            name: "Continuous Sex Abuse of a Child",
            
        },  
        {
            variable : "offense10" ,
            name: "Prostitution 1+ Convictions - Seller",
            
        }
    ], 
    casesYearOffenseLA : [
        { 
            variable : "offense1" ,
            name: "Trafficking of Persons",
            
        },
        { 
            variable : "offense2",
            name: "Internet Crimes Against Children",
            
        }, 
        {
            variable : "offense3" ,
            name: "Sexual Assault Against Minors",
            
        },  
        { 
            variable : "offense4" ,
            name: "Prostitution Offenses",
            
        }
    ], 
    agePenalCode: [ 
        { 
            variable : "chapter20" ,
            name: "Chapter 20A: Trafficking of Persons",
            
        },
        { 
            variable : "chapter21",
            name: "Chapter 21: Sexual Offenses",
            
        }, 
        {
            variable : "chapter22" ,
            name: "Chapter 22: Assaultive Offenses",
            
        },  
        { 
            variable : "chapter25" ,
            name: "Chapter 25: Offenses Against the Family",
            
        },
        { 
            variable : "chapter33" ,
            name: "Chapter 33: Computer Crimes",
            
        },  
        {
            variable : "chapter43" ,
            name: "Chapter 43: Public Indecency",
            
        }
    ], 
    ageOffenseCode: [ 
        { 
            variable : "10-17",
            name: "10-17",
            
        }, 
        {
            variable : "18-24" ,
            name: "18-24",
            
        },  
        { 
            variable : "25-29" ,
            name: "25-29",
            
        },
        { 
            variable : "30-39" ,
            name: "30-39",
            
        },  
        {
            variable : "40-49" ,
            name: "40-49",
            
        },  {
            variable : "50-59" ,
            name: "50-59",
            
        },  {
            variable : "60-69" ,
            name: "60-69",
            
        },  {
            variable : "70-79" ,
            name: "70-79",
            
        } ,{
            variable : "80-89" ,
            name: "80-89",
            
        }, {
            variable : ">89" ,
            name: ">89",
            
        }, {
            variable : "Unknown" ,
            name: "Unknown",
            
        }
    ],
    racePenalCode: [ 
        { 
            variable : "chapter20" ,
            name: "Chapter 20A: Trafficking of Persons",
            
        },
        { 
            variable : "chapter21",
            name: "Chapter 21: Sexual Offenses",
            
        }, 
        {
            variable : "chapter22" ,
            name: "Chapter 22: Assaultive Offenses",
            
        },  
        { 
            variable : "chapter25" ,
            name: "Chapter 25: Offenses Against the Family",
            
        },
        { 
            variable : "chapter33" ,
            name: "Chapter 33: Computer Crimes",
            
        },  
        {
            variable : "chapter43" ,
            name: "Chapter 43: Public Indecency",
            
        }
    ], 
    raceOffenseCode: [ 
        {
            variable : "American Indian or Alaskan Native" ,
            name: "American Indian/Alaskan Native",
            
        }, 
        { 
            variable : "Asian Or Pacific Islander" ,
            name: "Asian Or Pacific Islander",
            
        },
        { 
            variable : "Black",
            name: "Black",
            
        }, 
        { 
            variable : "Unknown" ,
            name: "Unknown",
            
        },
        { 
            variable : "White" ,
            name: "White",
            
        },  
    ],
    ethnicityPenalCode: [ 
        { 
            variable : "chapter20" ,
            name: "Chapter 20A: Trafficking of Persons",
            
        },
        { 
            variable : "chapter21",
            name: "Chapter 21: Sexual Offenses",
            
        }, 
        {
            variable : "chapter22" ,
            name: "Chapter 22: Assaultive Offenses",
            
        },  
        { 
            variable : "chapter25" ,
            name: "Chapter 25: Offenses Against the Family",
            
        },
        { 
            variable : "chapter33" ,
            name: "Chapter 33: Computer Crimes",
            
        },  
        {
            variable : "chapter43" ,
            name: "Chapter 43: Public Indecency",
            
        }
    ], 
    ethnicityOffenseCode: [ 
        { 
            variable : "Hispanic" ,
            name: "Hispanic",
            
        },
        { 
            variable : "Non-Hispanic",
            name: "Non-Hispanic",
            
        }, 
        {
            variable : "Unknown" ,
            name: "Unknown",
            
        },  
    ],
    genderPenalCode: [ 
        { 
            variable : "chapter20" ,
            name: "Chapter 20A: Trafficking of Persons",
            
        },
        { 
            variable : "chapter21",
            name: "Chapter 21: Sexual Offenses",
            
        }, 
        {
            variable : "chapter22" ,
            name: "Chapter 22: Assaultive Offenses",
            
        },  
        { 
            variable : "chapter25" ,
            name: "Chapter 25: Offenses Against the Family",
            
        },
        { 
            variable : "chapter33" ,
            name: "Chapter 33: Computer Crimes",
            
        },  
        {
            variable : "chapter43" ,
            name: "Chapter 43: Public Indecency",
            
        }
    ], 
    genderOffenseCode: [ 
        { 
            variable : "Female" ,
            name: "Female",
            
        },
        { 
            variable : "Male",
            name: "Male",
            
        }, 
        {
            variable : "Unknown" ,
            name: "Unknown",
            
        },  
    ],
    citizenshipPenalCode: [ 
        { 
            variable : "chapter20" ,
            name: "Chapter 20A: Trafficking of Persons",
            
        },
        { 
            variable : "chapter21",
            name: "Chapter 21: Sexual Offenses",
            
        }, 
        {
            variable : "chapter22" ,
            name: "Chapter 22: Assaultive Offenses",
            
        },  
        { 
            variable : "chapter25" ,
            name: "Chapter 25: Offenses Against the Family",
            
        },
        { 
            variable : "chapter33" ,
            name: "Chapter 33: Computer Crimes",
            
        },  
        {
            variable : "chapter43" ,
            name: "Chapter 43: Public Indecency",
            
        }
    ], 
    citizenshipOffenseCode: [ 
        { 
            variable : "total" ,
            name: "Total Offenses",
            
        },
    ],

}