// import { countyData as countyJson } from "../Data/countyJson";
// import { txDistrictJson } from "../Data/txDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'

export const mapsTXData = {
  mapZoom: 5,
  reportMapZoom: 4,
  mapBoxCenter: [-99.901, 37.0586],
  mapBoxCenterReport: [-114.77287069367998, 31.0586],
  mapMaxBounds: [[-113.77287069367998, 16.7091], [-86.26493500455376, 37.743288738760796]],
  mapMaxBoundsReport: [[-114.77287069367998, 16.7091], [-86.26493500455376, 37.743288738760796]],
  reportMapWidth: '577px',

  'Federal Cases': {
    mapZoom: 4,
    reportMapZoom: 3,
    mapBoxCenter: [-98.5795, 39.8282],
    mapBoxCenterReport: [-98.5795, 39.8282],
    mapMaxBounds: '',
    mapMaxBoundsReport: '',
    reportMapWidth: '100%',
    'Location ': mapsNationalData['Federal Cases']['Location '],
    mapInfo: mapsNationalData['Federal Cases'].mapInfo,
  },
    'State Policy': {
      mapZoom: 4,
      reportMapZoom: 3,
      mapBoxCenter: [-98.5795, 39.8282],
      mapBoxCenterReport: [-98.5795, 39.8282],
      mapMaxBounds: '',
      mapMaxBoundsReport: '',
      reportMapWidth: '100%',
      'Data': mapsNationalData['State Policy']['Data'],
      mapInfo: mapsNationalData['State Policy'].mapInfo
    }
}