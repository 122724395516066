import React, { useRef, useEffect } from "react";
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function CareCoordinationLegend(props) {
    const buttonRef = useRef(null)
    const popperRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [popperRefHeight, setPopperRefHeight] = React.useState(100)

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;

    useEffect(() => {
        if (props.report) {
            setAnchorEl(buttonRef.current)
            if (popperRef.current && popperRef.current.clientHeight) {

                setPopperRefHeight(popperRef.current.clientHeight)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.report, popperRef.current])

    return (
        <div className={props.report ? ' mapLegendWrapper reportMapLegendWrapper' : 'mapLegendWrapper'}
            style={props.report && { height: `calc(${popperRefHeight}px + 30px)` }}
        >
            <Button ref={buttonRef} aria-describedby={id} variant="contained" color="default" type="button" onClick={handleClick} className="filterButton legendButton">
                <span>Legend</span>
                <ArrowDropDownIcon />
            </Button>
            <Popper ref={popperRef} id={id} open={open} anchorEl={anchorEl} placement={'top-start'} transition
                disablePortal // (the children will stay within their parent DOM hierarchy for pdf's to capture it)
                modifiers={props.report && {
                    flip: {
                        enabled: false,
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <div className="maplegend">
                                <div className="field noTitle" style={{ display: "flex", alignItems: "center" }}>
                                    <label
                                        htmlFor={'cc'}
                                        className='chartCheckboxContainer'
                                    >
                                        Care Coordination Locations
                                        <input
                                            type="checkbox"
                                            className='chartCheckboxes'
                                            checked={false}
                                            onChange={(e) => console.log('')}
                                        />
                                        <span
                                            className="chartCheckmark"
                                            onClick={(e) => console.log('')}
                                            style={{
                                                backgroundColor: props.chartColorArray[0],
                                                borderColor: props.chartColorArray[0]
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    )
}