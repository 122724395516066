/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import {
  select,
  scaleBand,
  event,
  axisBottom,
  axisLeft,
  axisRight,
  scaleLinear,
  max,
  nest,
  format,
  line,
} from "d3";


export const GroupedBarVariableXGraph = (props) => {
  const allKeys = props.keys.map((item) => {
    return item.variable;
  });

  var data;
  if (props.data) {
    data = props.data;
  } else {
    data = [];
  }

  // eslint-disable-next-line no-unused-vars
  const [keys, setKeys] = useState(allKeys);
  //If a trendline, adding values to state
  const [trendLine, setTrendLine] = useState(true);
  const [trendLineLegend, setTrendLineLegend] = useState(true);
  const [percentLine, setPercentLine] = useState(true);
  const [percentLineLegend, setPercentLineLegend] = useState(true);

  const { chartColorArray } = useAppContext()

  let colors = {};
  for (let i = 0; i < props.keys.length; i++) {
    colors[props.keys[i].variable] = chartColorArray[i];
  }

  const svgRef = useRef();
  const wrapperRef = useRef();

  const handleLegendClick = (e, key) => {
    console.log('handleLegendClick key', keys, key)
    if (keys.includes(key)) {
      setKeys(keys.filter((_key) => _key !== key));
    } else {
      var newArray = Array.from(new Set([...keys, key]));
      var sortedArray = [];
      for (let i = 0; i < allKeys.length; i++) {
        for (let j = 0; j < newArray.length; j++) {
          if (allKeys[i] === newArray[j]) {
            sortedArray.push(newArray[j]);
          }
        }
      }
      setKeys(sortedArray);
    }
  };

  // Click handler for trendline in legend
  const handleLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!trendLine)
    setTrendLineLegend(!trendLineLegend);
    setTrendLine(!trendLine);
  };

  // Click handler for perventline in legend
  const handlePercentLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!percentLine)
    setPercentLineLegend(!percentLineLegend);
    setPercentLine(!percentLine);
  };

  useEffect(() => {
    function endTooltip() {
      const boxes = document.querySelectorAll('.tooltip');

      boxes.forEach(box => {
        box.style.opacity = 0;
      });
    }
    window.addEventListener('scroll', endTooltip)
    //Selecting the container that will hold the D3 graph
    const svg = select(svgRef.current);

    //Removing the previous lines and data, in order to re-draw them when the filters are changed
    svg.selectAll(".rect").remove();
    svg.selectAll(".group").remove();
    svg.selectAll(".group-label").remove();
    svg.selectAll(".axis").remove();
    svg.selectAll(".category-label").remove();
    svg.selectAll(".line").remove();
    svg.selectAll(".dots").remove();
    svg.selectAll(".myCircle").remove();
    svg.selectAll(".axislabel").remove();
    svg.selectAll(".x-axis").remove();

    // Setting the height and width of the graph (responsive to viewport size)
    var margin = { top: 30, right: 0, bottom: 90, left: 80 };
    var width;
    var height;
    if (window.innerWidth > 1200 && window.innerWidth < 1440) {
      width = 900;
      height = 500;
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      width = 900;
      height = 500;
    } else {
      width = 1200;
      height = 500;
    }

    if (props.percentLine) {
      width = 850;
    }

    if (props.report) {
      width = 550;
    }

    //Creating the hover tooltip
    var tooltip = select(wrapperRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px");
    tooltip.append("div").attr("class", "title");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    // Creating all of the axis calculations
    var x_groups = scaleBand()
      .range([margin.left, width - margin.right])
      .padding(0.1);

    var x_values = scaleBand();

    var y = scaleLinear().range([height - margin.bottom, margin.top]);

    var groups_axis = axisBottom(x_groups);

    // Creating the data structure for grouped bars
    var nested = nest()
      .key(function (d) {
        return d.xaxis;
      })
      .rollup(function (leaves) {
        var nestedData = [];
        for (let i = 0; i < keys.length; i++) {
          nestedData.push({
            key: keys[i],
            value: leaves[0][keys[i]],
          });
        }
        return nestedData;
      })
      .entries(data);

    x_groups.domain(
      nested.map(function (d) {
        return d.key;
      })
    );

    x_values.domain(allKeys).rangeRound([0, x_groups.bandwidth()]);

    var yMaxValue;
    if (trendLine && props.line) {
      yMaxValue = max(props.line, (d) => d.irp_youthactivecare);
    } else if (props.yaxisMaxValue) {
      yMaxValue = max(nested, function (d) {
        return max(d.values, function (d) {
          return max(d.value, function (d) {
            return d.value;
          });
        });
      });
    } else if (props.multipleYMaxValues) {
      // get max y value based on max num of an individual bar not the total of an x-axis bars
      let eachBarValue = []
      data.forEach(obj => {
        props.multipleYMaxValues.forEach(variable => {
          return eachBarValue.push(Number(obj[variable]))
        })
      })
      yMaxValue = max(eachBarValue)
    } else {
      yMaxValue = max(data, (d) => Number(d.total));
    }

    if (!yMaxValue) {
      yMaxValue = 0;
    }

    y.domain([0, yMaxValue]);

    var ticks = y.ticks(),
      lastTick = ticks[ticks.length - 1],
      newLastTick = lastTick + (ticks[1] - ticks[0]);
    ticks.push(newLastTick);

    y.domain([0, newLastTick]);

    var formatxAxis = format(".0f");
    var yAxis;

    if (yMaxValue < 20) {
      yAxis = axisLeft(y).ticks(yMaxValue).tickFormat(formatxAxis);
    } else {
      yAxis = axisLeft(y).tickValues(ticks);
    }

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", "translate(0," + (height - 75) + ")")
      .call(groups_axis);

    svg
      .append("g")
      .attr("class", "y axis")
      .attr("transform", `translate(${80}, 0 )`)
      .call(yAxis);

    var groups_g = svg
      .selectAll(".group")
      .data(nested)
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "group group-" + d.key;
      })
      .attr("transform", function (d) {
        return "translate(" + x_groups(d.key) + ",0)";
      });

    var values_g = groups_g
      .selectAll(".value")
      .data(function (d) {
        return d.value;
      })
      .enter()
      .append("g")
      .attr("class", function (d) {
        return "value value-" + d.key;
      })
      .attr("transform", function (d) {
        return "translate(" + x_values(d.key) + ",0)";
      });

    // eslint-disable-next-line no-unused-vars
    var rects = values_g
      .selectAll(".rect")
      .data(function (d) {
        return [d];
      })
      .enter()
      .append("rect")
      .attr("class", "rect")
      .attr("width", x_values.bandwidth() > 60 ? 60 : x_values.bandwidth())
      .attr("x", function (d) {
        return 0;
      })
      .attr("y", function (d) {
        return y(d.value);
      })
      .attr("height", function (d) {
        if (d.value === 0) {
          return 0;
        } else {
          return height - y(d.value) - margin.bottom;
        }
      })
      .style("fill", function (d) {
        return colors[d.key];
      })
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove);

    //Hover for bar graph
    function onMouseOver(d) {
      tooltip.style("opacity", 0);
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");

      var subgroupName = select(this.parentNode).datum().key;
      var label;
      for (let i = 0; i < props.keys.length; i++) {
        if (subgroupName === props.keys[i].variable) {
          label = props.keys[i].name;
        }
      }
      var tooltipData = select(this).data()[0];
      var total = tooltipData.value
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      tooltip.select(".label").text("Total: " + total);
      tooltip.select(".title").text(label);
    }

    function onMouseOut() {
      tooltip.style("opacity", 0);
      tooltip.style("z-index", "-1");
    }

    function onMouseMove(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");

      tooltip
        .style("top", event.clientY - 100 + "px")
        .style("left", event.clientX - 150 + "px");
    }

    // **************** TrendLine **************

    if (props.trendLine) {
      var lineData;
      if (props.line) {
        // eslint-disable-next-line
        lineData = props.line.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        });
      } else {
        // eslint-disable-next-line
        lineData = data.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        });
      }

      if (trendLine && lineData.length > 1) {
        //Adding line and circle hover
        var totalLine;
        if (props.line) {
          totalLine = line()
            .x((d) => x_groups(d.name) + x_groups.bandwidth() / 2)
            .y((d) => y(d.irp_youthactivecare));
        } else {
          totalLine = line()
            .x((d) => x_groups(d.xaxis) + x_groups.bandwidth() / 2)
            .y((d) => y(d.total));
        }

        svg
          .append("path")
          .datum(lineData)
          .attr("fill", "none")
          .attr("stroke", "#212121")
          .attr("stroke-width", 2)
          .attr("class", "line")
          .attr("d", totalLine);

        let Tooltip = select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");

        let mouseover = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("opacity", 1);
          Tooltip.style("z-index", "9999");
        };
        let mousemove = function (d) {
          if (props.lineTitle) {
            Tooltip.html(
              props.lineTitle +
              ": " +
              d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            )
              .style("top", event.clientY - 75 + "px")
              .style("left", event.clientX - 150 + "px");
          } else {
            Tooltip.html(
              props.trendLineLegend +
              ": " +
              d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            )
              .style("top", event.clientY - 75 + "px")
              .style("left", event.clientX - 150 + "px");
          }
        };
        let mouseleave = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("z-index", "-1");
        };

        svg
          .append("g")
          .selectAll("dot")
          .data(lineData)
          .enter()
          .append("circle")
          .attr("class", "myCircle")
          .attr("cx", function (d) {
            return x_groups(d.xaxis) + x_groups.bandwidth() / 2;
          })
          .attr("cy", function (d) {
            return y(props.line ? d.irp_youthactivecare : d.total);
          })
          .attr("r", 3)
          .attr("stroke", "#000000")
          .attr("stroke-width", 3)
          .attr("fill", "#000000")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);
      } else {
        svg.selectAll(".trendLine").remove();
        svg.selectAll(".dot").remove();
      }
    }

    // // Percent Line
    if (props.percentLine && percentLine) {
      // eslint-disable-next-line
      var percentLineExtraData = data.filter((item) => {
        if (
          item.total !== 0 &&
          item[allKeys[0]] !== 0 &&
          item[allKeys[1]] !== 0
        ) {
          return item;
        }
      });

      var linePercentData = percentLineExtraData.map((item) => {
        item.percent = (
          (Number(item.newindividuals) / Number(item.total)) *
          100
        ).toFixed(2);
        return item;
      });

      var y2 = scaleLinear().range([height - margin.bottom, margin.top]);

      y2.domain([0, 100]);

      var y2Axis = axisRight(y2).tickFormat((d) => d + "%");

      svg
        .append("g")
        .attr("class", "y axis")
        .attr("transform", `translate(${width}, 0 )`)
        .call(y2Axis);

      if (percentLine && linePercentData.length > 1) {
        //Adding line and circle hover
        const totalLine = line()
          .x((d) => x_groups(d.xaxis) + x_values.bandwidth())
          .y((d) => y2(d.percent));

        svg
          .append("path")
          .datum(linePercentData)
          .attr("fill", "none")
          .attr("stroke", "#28ADE3")
          .attr("stroke-width", 2)
          .attr("class", "line")
          .attr("d", totalLine);

        let Tooltip = select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");

        let mouseover = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("opacity", 1);
          Tooltip.style("z-index", "9999");
        };
        let mousemove = function (d) {
          Tooltip.html(`${props.percentLegend}: ` + d.percent)
            .style("top", event.clientY - 75 + "px")
            .style("left", event.clientX - 150 + "px");
        };
        let mouseleave = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("z-index", "-1");
        };

        svg
          .append("g")
          .selectAll("dot")
          .data(linePercentData)
          .enter()
          .append("circle")
          .attr("class", "myCircle")
          .attr("cx", function (d) {
            return x_groups(d.xaxis) + x_values.bandwidth();
          })
          .attr("cy", function (d) {
            return y2(d.percent);
          })
          .attr("r", 3)
          .attr("stroke", "#000000")
          .attr("stroke-width", 3)
          .attr("fill", "#000000")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseleave", mouseleave);
      } else {
        svg.selectAll(".trendLine").remove();
        svg.selectAll(".dot").remove();
        svg.selectAll(".myCircle").remove();
      }
    }

    // text label for the x axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr(
        "transform",
        "translate(" + (width + 60) / 2 + " ," + (height - 20) + ")"
      )
      .style("text-anchor", "middle")
      .text(props.xaxis);

    // text label for the y axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(props.yaxis);
  }, [
    keys,
    props.yaxisMaxValue,
    props.multipleYMaxValues,
    props.trendLineLegend,
    props.report,
    trendLine,
    props.trendLine,
    allKeys,
    colors,
    props.keys,
    props.xaxis,
    props.yaxis,
    data,
    props.percentLine,
    percentLine,
    props.percentLegend,
    props.line,
    props.lineTitle,
  ]);

  return (
    <div
      className={props.smalltext ? "graph-stacked" : "graph"}
      id={props.id}
      style={{ position: "relative", height: 550 }}
    >
      <div ref={wrapperRef} style={{ position: "relative", height: 500 }}>
        <svg ref={svgRef} style={{ width: "100%", height: 500 }}></svg>
      </div>
      <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
        {allKeys.map((key, index) => {
          var labelId;
          for (let i = 0; i < props.keys.length; i++) {
            if (key === props.keys[i].variable) {
              labelId = props.keys[i].name;
            }
          }
          return (
            <div
              key={key}
              className="field"
              style={{
                display: "flex",
                marginLeft: "20px",
                alignItems: "center",
              }}
            >
              <label
                htmlFor={key}
                className='chartCheckboxContainer'
              >
                {labelId}
                <input
                  type="checkbox"
                  className='chartCheckboxes'
                  checked={props.report ? false : keys.includes(key)}
                  onChange={(e) => console.log('')}
                />
                <span
                  className="chartCheckmark"
                  onClick={(e) => props.report ? console.log('') : handleLegendClick(e, key)}
                  style={{
                    backgroundColor: props.report || keys.includes(key) ? colors[key] : 'transparent',
                    borderColor: props.report || keys.includes(key) ? colors[key] : '#0000008a'
                  }}
                />
              </label>
            </div>
          );
        })}
        {data.length > 1 && props.trendLine ? (
          <div
            className="field"
            style={{
              display: "flex",
              marginLeft: "20px",
              alignItems: "center",
            }}
          >
            <label
              className='chartCheckboxContainer'
            >
              {props.line ? props.lineTitle : props.trendLineLegend}
              <input
                type="checkbox"
                className='chartCheckboxes'
                checked={props.report ? false : trendLineLegend}
                onChange={(e) => console.log('')}
              />
              <span
                className="chartCheckmark"
                onClick={(e) => props.report ? console.log('') : handleLegendLineClick(e)}
                style={{
                  backgroundColor: props.report || trendLineLegend ? 'black' : 'transparent',
                  borderColor: props.report || trendLineLegend ? 'black' : '#0000008a'
                }}
              />
            </label>
          </div>
        ) : null}
        {data.length > 1 && props.percentLine ? (
          <div
            className="field"
            style={{
              display: "flex",
              marginLeft: "20px",
              alignItems: "center",
            }}
          >
            <label
              className='chartCheckboxContainer'
            >
              {props.percentLegend}
              <input
                type="checkbox"
                className='chartCheckboxes'
                checked={props.report ? false : percentLineLegend}
                onChange={(e) => console.log('')}
              />
              <span
                className="chartCheckmark"
                onClick={(e) => props.report ? console.log('') : handlePercentLegendLineClick(e)}
                style={{
                  backgroundColor: props.report || percentLineLegend ? 'black' : 'transparent',
                  borderColor: props.report || percentLineLegend ? 'black' : '#0000008a'
                }}
              />
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};
