import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";

export const SingleBarDottedGraph = (props) => {
    const { chartColorArray } = useAppContext()
    const wrapperRef = useRef();
    const svgRef = useRef();

    const [trendLine, setTrendLine] = useState(true);

    const handleLegendLineClick = (e) => {
        // If chartLine is set to false in this component, update props
        props.updateChartLineShowing(!trendLine)

        setTrendLine(!trendLine);
    };

    useEffect(() => {
        function endTooltip() {
            const boxes = document.querySelectorAll('.tooltip');

            boxes.forEach(box => {
                box.style.opacity = 0;
            });
        }
        window.addEventListener('scroll', endTooltip)
        //Setting the width and height
        var margin = { top: 20, right: 0, bottom: 60, left: 80 };
        var width;
        var height = 500;
        if (window.innerWidth > 1200 && window.innerWidth < 1440) {
            width = 900;
        } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
            width = 900;
        } else {
            width = 1200;
        }
        if (props.widthNeeded) {
            width = props.widthNeeded;
        } else if (props.report) {
            width = 700
        }

        // Formatting xaxis titles and bar colors
        const titles = props.keys.map((item) => {
            if (item.quarter) {
                return `${item.name} ${item.quarter}`;
            } else if (props.xaxisLabelsKey) {
                return item[props.xaxisLabelsKey];
            } else {
                return item.name;
            }
        });

        const colors = d3.scaleOrdinal(chartColorArray)

        //For reports
        var data;
        if (props.type === "cc") {
            data = props.data;
        } else {
            data = props.data.referrals;
        }

        if (data.length < 5) {
            width = 600;
        }

        //Calculating axis
        var max = null;

        max = d3.max(data, (d) => +d.max);

        //Selecting the svg element and removing past svg's (from filter)
        let svg = d3.select(svgRef.current);
        svg.selectAll("svg").remove();

        //Creating SVG
        var barsvg = svg
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", "translate(" + 0 + "," + 0 + ")");

        // Creating the tooltip
        var tooltip = d3
            .select(wrapperRef.current)
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("color", "black")
            .style("border", "solid")
            .style("border-width", "2px")
            .style("border-radius", "5px")
            .style("position", "fixed")
            .style("z-index", "-1")
            .style("padding", "5px");
        tooltip.append("div").attr("class", "text1");
        tooltip.append("div").attr("class", "text2");
        tooltip.append("div").attr("class", "text3");

        // set the ranges
        var x = d3
            .scaleBand()
            .domain(d3.range(data.length))
            .range([margin.left, width - margin.right])
            .padding(0.1);
        var y = d3
            .scaleLinear()
            .domain([0, max])
            .nice()
            .range([height - margin.bottom, margin.top]);

        var xAxis = (g) =>
            g.attr("transform", `translate(0,${height - margin.bottom})`).call(
                d3
                    .axisBottom(x)
                    .tickFormat((i) => titles[i])
                    .tickSizeOuter(0)
            );

        var formatxAxis = d3.format(".0f");
        var yAxis;
        if (max < 20) {
            yAxis = (g) =>
                g
                    .attr("transform", `translate(${60},0)`)
                    .call(d3.axisLeft(y).ticks(max).tickFormat(formatxAxis))
                    .attr("class", "y-axis")
                    .call((g) =>
                        g
                            .append("text")
                            .attr("x", -margin.left)
                            .attr("y", 10)
                            .attr("text-anchor", "start")
                            .text(data.y)
                    );
        } else {
            yAxis = (g) =>
                g
                    .attr("transform", `translate(${80},0)`)
                    .call(d3.axisLeft(y).ticks(null, data.y))
                    .attr("class", "y-axis")
                    .call((g) =>
                        g
                            .append("text")
                            .attr("x", -margin.left)
                            .attr("y", 10)
                            .attr("text-anchor", "start")
                            .text(data.y)
                    );
        }

        // Adding the dotted bars
        barsvg
            .append("g")
            .selectAll("rect")
            .data(data)
            .join("rect")
            .attr("x", (d, i) => x(i) + 1)
            .attr("y", (d) => y(d.max) + 1)
            .attr("height", (d) => (y(d.value) - y(d.max)) + 1)
            .attr("width", x.bandwidth() - 2)
            .style("stroke-dasharray", "3,2")
            .style("stroke", "grey")
            .style("stroke-width", "2px")
            .attr("fill", 'transparent');

        // hiding bottom dotted line
        barsvg
            .append("g")
            .selectAll("rect")
            .data(data)
            .join("rect")
            .attr("x", (d, i) => x(i))
            .attr("y", (d) => y(d.value))
            .attr("height", "4px")
            .attr("width", x.bandwidth())
            .attr("fill", '#fff');

        // Adding the main bars
        barsvg
            .append("g")
            .selectAll("rect")
            .data(data)
            .join("rect")
            .on("mouseover", onMouseOver)
            .on("mouseout", onMouseOut)
            .on("mousemove", onMouseMove)
            .attr("x", (d, i) => x(i))
            .attr("y", (d) => {
                if (d.value) {
                    return y(d.value);
                } else if (d.total) {
                    return y(d.total);
                }
            })
            .attr("height", (d) => {
                if (d.value) {
                    return y(0) - y(d.value);
                } else if (d.total) {
                    return y(0) - y(d.total);
                }
            })
            // .attr("width", x.bandwidth() > 90 ? 60 : x.bandwidth())
            //.attr("width", x.bandwidth() > 60 ? 60 : x.bandwidth())
            .attr("width", x.bandwidth())
            .attr("fill", (d, i) => {
                return colors(i);
            });


        function onMouseOver(d) {
            tooltip.style("opacity", 1)
            tooltip.style("z-index", "9999");

            tooltip
                .select(".text1")
                .text(d.xaxis);

            tooltip
                .select(".text2")
                .text(
                    "Total: " +
                    (d.value ? d.value : d.total)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
            tooltip.select(".text3").text(`Percent: ${d.percent}%`);
        }

        function onMouseOut() {
            tooltip.style("opacity", 0)
                .style("z-index", "-1");
        }

        function onMouseMove(d) {
            tooltip
                .style("opacity", 1)
                .style("z-index", "9999")
                .style("top", d3.event.clientY - 150 + "px")
                .style("left", d3.event.clientX - 150 + "px");
        }

        barsvg
            .append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis)
            .selectAll(".tick text")
            .style("font-size", props.xaxisFontSize ?? '12px')
            .call(wrap, x.bandwidth()); // 6

        barsvg.append("g").call(yAxis);

        // text label for the x axis
        barsvg
            .append("text")
            .attr("class", "axislabel")
            .attr("transform", "translate(" + ((width + margin.left) / 2) + " ," + (height - 5) + ")")
            .style("text-anchor", "middle")
            .text(props.xaxis);

        // text label for the y axis
        barsvg
            .append("text")
            .attr("class", "axislabel")
            .attr("transform", "rotate(-90)")
            .attr("y", 0)
            .attr("x", 0 - height / 2)
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text(props.yaxis);

        // wrap x axis labels function
        function wrap(text, width) {
            text.each(function () {
                var text = d3.select(this),
                    words = text.text().split(/[ /]+/).reverse(), // /\s+/
                    word,
                    line = [],
                    lineNumber = 0,
                    lineHeight = 1.1, // ems
                    y = text.attr("y"),
                    dy = 0.5, // parseFloat(text.attr("dy")),
                    tspan = text
                        .text(null)
                        .append("tspan")
                        .attr("x", 0)
                        .attr("y", y)
                        .attr("dy", dy + "em");
                while ((word = words.pop())) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = text
                            .append("tspan")
                            .attr("x", 0)
                            .attr("y", y)
                            .attr("dy", ++lineNumber * lineHeight + dy + "em")
                            .text(word);
                    }
                }
            });
        }

        // ********************* Start of Trend line *********************
        if (props.trendLine) {
            // eslint-disable-next-line array-callback-return
            let lineData = data.filter((item, key) => {
                if (item.value) {
                    return item;
                } else {
                    item.value = "0";
                    return item;
                }
            });

            //if (lineData.length > 1) {
            if (trendLine && lineData.length > 1) {

                //Adding line and circle hover
                const totalLine = d3
                    .line()
                    .x((d, i) => x(i) + x.bandwidth() / 2)
                    .y((d) => y(d.value ? d.value : d.total));

                barsvg
                    .append("path")
                    .datum(lineData)
                    .attr("fill", "none")
                    .attr("stroke", "#212121")
                    .attr("stroke-width", 2)
                    .attr("class", "line trendLine")
                    .attr("d", totalLine);

                let Tooltip = d3
                    .select(wrapperRef.current)
                    .append("div")
                    .style("opacity", 0)
                    .attr("class", "tooltip")
                    .style("background-color", "white")
                    .style("color", "black")
                    .style("border", "solid")
                    .style("border-width", "2px")
                    .style("border-radius", "5px")
                    .style("position", "fixed")
                    .style("z-index", "-1")
                    .style("padding", "5px");
                Tooltip.append("div").attr("class", "label");
                Tooltip.append("div").attr("class", "percent");

                // Tberry added code here
                const mouseover = (d) => {
                    Tooltip.style("opacity", 1);
                    Tooltip.style("z-index", "9999");
                    if (this.props.imbByYear) {
                        var percent = 0;
                        for (let i = 1; i < this.props.data.length; i++) {
                            console.log("d", d);

                            console.log("name", this.props.data[i].name);
                            if (this.props.data[i].name === d.name) {
                                percent = (
                                    this.props.data[i].value / this.props.data[i - 1].value
                                ).toFixed(2);
                            }
                        }
                        if (d.name !== "2016") {
                            Tooltip.select(".percent").text("Percent Change: " + percent + "%");
                        } else if (d.name === "2016") {
                            Tooltip.select(".percent").text("");
                        }
                    }
                };
                const mousemove = (d) => {
                    if (props.lineTitle) {
                        Tooltip.select(".label").text(
                            props.lineTitle +
                            ": " +
                            (d.value ? d.value : d.total)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        );
                        Tooltip.style("top", d3.event.clientY - 100 + "px") // - 75
                            .style("left", d3.event.clientX - 150 + "px");
                    } else {
                        Tooltip.select(".label").text(
                            props.trendLineLegend +
                            ": " +
                            (d.value ? d.value : d.total)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        );
                        Tooltip.style("top", d3.event.clientY - 100 + "px").style(
                            "left",
                            d3.event.clientX - 150 + "px"
                        );
                        if (this.props.imbByYear) {
                            var percent = 0;
                            for (let i = 1; i < this.props.data.length; i++) {
                                console.log("d", d);
                                if (this.props.data[i].name === d.name) {
                                    console.log("data[i] name", this.props.data[i].name);
                                    console.log("d name", d.name);
                                    percent = (
                                        this.props.data[i].value / this.props.data[i - 1].value
                                    ).toFixed(2);
                                }
                            }
                            if (d.name !== "2016") {
                                Tooltip.select(".percent").text(
                                    "Percent Change: " + percent + "%"
                                );
                            } else if (d.name === "2016") {
                                Tooltip.select(".percent").text("");
                            }
                        }
                    }
                };
                const mouseleave = (d) => {
                    Tooltip.style("opacity", 0);
                    Tooltip.style("z-index", "-1");
                };

                barsvg
                    .append("g")
                    .selectAll("dot")
                    .data(lineData)
                    .enter()
                    .append("circle")
                    .attr("class", "myCircle")
                    .attr("cx", function (d, i) {
                        return x(i) + x.bandwidth() / 2;
                    })
                    .attr("cy", function (d) {
                        return y(d.value ? d.value : d.total);
                    })
                    .attr("r", 3)
                    .attr("stroke", "#000000")
                    .attr("stroke-width", 3)
                    .attr("fill", "#000000")
                    .on("mouseover", mouseover)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave);
            } else {
                svg.selectAll(".trendLine").remove();
                svg.selectAll(".dot").remove();
                svg.selectAll(".myCircle").remove();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.data,
        props.colors,
        props.keys,
        props.yaxisMaxValue,
        props.trendLineLegend,
        props.report,
        props.widthNeeded,
        props.trendLine,
        trendLine,
        props.xaxis,
        props.yaxis,
        props.percentLine,
        props.percentLegend,
        props.line,
        props.lineTitle,
        props.graphVariable,
        props.type,
    ]);

    return (
        <div id={props.id} className={props.smalltext ? "graph" : "graph1"}>
            <div ref={wrapperRef} style={{ position: "relative" }}>
                <div ref={svgRef} />
            </div>

            {props.trendLine ? (
                <div
                    className="field"
                    style={{
                        display: "flex",
                        marginLeft: "20px",
                        alignItems: "center",
                    }}
                >
                    <label
                        className='chartCheckboxContainer'
                    >
                        {props.trendLineLegend}
                        <input
                            type="checkbox"
                            className='chartCheckboxes'
                            checked={props.report ? false : trendLine}
                            onChange={() => console.log('')}
                        />
                        <span
                            className="chartCheckmark"
                            onClick={(e) => props.report ? console.log('') : handleLegendLineClick(e)}
                            style={{
                                backgroundColor: props.report || trendLine ? 'black' : 'transparent',
                                borderColor: props.report || trendLine ? 'black' : '#0000008a'
                            }}
                        />
                    </label>
                </div>
            ) : null}
        </div>
    );
};
