// import { parishData as parishJson } from '../Data/parishJson'
// import { laDistrictJson } from "../Data/laDistrictJson";
import { mapsNationalData } from '../Data/mapsNationalData'

export const mapsLAData = {
    mapZoom: 5,
    reportMapZoom: 4,
    mapBoxCenter: [-92.329102, 30.39183],
    mapBoxCenterReport: [-92.329102, 30.39183],
    mapMaxBounds: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
    mapMaxBoundsReport: [[-97.77287069367998, 25.7091], [-87.26493500455376, 35.743288738760796]],
    reportMapWidth: '577px',

    'Federal Cases': {
        mapZoom: 4,
        reportMapZoom: 3,
        mapBoxCenter: [-98.5795, 39.8282],
        mapBoxCenterReport: [-98.5795, 39.8282],
        mapMaxBounds: '',
        mapMaxBoundsReport: '',
        reportMapWidth: '100%',

        'Location ': mapsNationalData['Federal Cases']['Location '],
        mapInfo: mapsNationalData['Federal Cases'].mapInfo,
    },
    'State Policy': {
      mapZoom: 4,
      reportMapZoom: 3,
      mapBoxCenter: [-98.5795, 39.8282],
      mapBoxCenterReport: [-98.5795, 39.8282],
      mapMaxBounds: '',
      mapMaxBoundsReport: '',
      reportMapWidth: '100%',
  
      'Data': mapsNationalData['State Policy']['Data'],
      mapInfo: mapsNationalData['State Policy'].mapInfo
    }
}