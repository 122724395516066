import React, { useEffect, useState } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";

export default function GridChart(props) {
  const { chartColorArray } = useAppContext()
    const anchor = React.createRef();

    //Only for graphs with legends
    const [keys, setKeys] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState(props.legendCategories ? props.legendCategories : []);


    const handleLegendClick = (e, key) => {
      if (selectedKeys.includes(key)) {
        setSelectedKeys(selectedKeys.filter((_key) => _key !== key));
      } else {
        var newArray = Array.from(new Set([...selectedKeys, key]));
        setSelectedKeys(newArray);
      }
    };

    // const specificPolicy = (item) => {
    //   let color;
    //   if (item === 'prevention') {
    //     color = chartColorArray[0]
    //   }
    //   if (item === 'protection') {
    //     color = chartColorArray[6]
    //   }
    //   if (item === 'prosecution') {
    //     color = chartColorArray[13]
    //   }
    //   return color;
    // }
  
    
  useEffect(() => {
    var margin = { top: 20, right: 80, bottom: 90, left: 80 };
    var width = 900;
    var height = 750;

    if (props.report) {
      width = 1000;
    }
    // eslint-disable-next-line no-unused-vars
    const colors = props?.colors ?
      d3.scaleOrdinal(props.colors)
        :
      d3.scaleOrdinal(chartColorArray);

    var xmax = 0;
    var ymax = 0;
    if (props.data){
      var data = props.data;

      //Clickable Legend
      if (props.legendCategories) {
        setKeys(props.legendCategories)
        data = data.filter(item => selectedKeys.includes(item.type))
      }

      xmax = d3.max(data, (d) => +d[props.xaxisValue]) + 1;
      ymax = d3.max(data, (d) => +d[props.yaxisValue]);

  
      const svg = d3.select(anchor.current);
  
      svg.selectAll("svg").remove();
      svg.selectAll(".tooltip").remove();
  
      var barsvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(0, 0)`);
  
      var tooltip = d3
        .select(anchor.current)
        .append("div")
        .attr("class", "tooltip")
          .style("opacity", 0)
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          // .style("z-index", "-1")
          .style("padding", "5px")
        .style("width", "200px");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "xaxis");
      tooltip.append("div").attr("class", "yaxis");
  
      var x = d3
        .scaleLinear()
        .domain([0, xmax])
        .range([margin.left, width - margin.right])
        //.nice();
  
      var y = d3
        .scaleLinear()
        //.domain(d3.range(data.length))
        .domain([0, ymax])
        .range([height - margin.bottom, margin.top])

        var xAxisFormat;
        if (xmax < 20) {
          xAxisFormat = d3.axisBottom(x).ticks(xmax);
        } else {
          xAxisFormat = d3.axisBottom(x).tickSizeOuter(0);
        }
  
      var xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(xAxisFormat);
        
      //var yAxis = d3.axisLeft(y).ticks(10);
      var yAxis = (g) =>
        g
          .attr("transform", `translate(${80},0)`)
          .call(d3.axisLeft(y).ticks(null, data.y))
          .attr("class", "y-axis")
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
          );
    
    //   var yAxis = (g) =>
    //     g.attr("transform", `translate(${margin.left},0)`).call(
    //       d3
    //         .axisLeft(y)
    //         //.tickSize(0)
    //         .ticks(5)
    //         //.tickFormat((i) => data[i][props.yaxisValue].length > 0 ? data[i][props.yaxisValue] : 'Unknown')
    //     ).selectAll(".tick text")
    //       .call(wrap, margin.yLabelsWidth - 25);
  
      barsvg
        .append("g")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("x", (d) => x(d[props.xaxisValue]))
        .attr("y", (d) => y(d[props.yaxisValue]))
        .attr("height", 35)
        .attr("width", 35)
        .attr("fill", "none")
        .style("stroke", "black") 



        barsvg
        .append("g")
        .selectAll("text")
        .data(data)
        .join("text")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .text((d) => d.label)
        .attr("x", (d) => x(d[props.xaxisValue]) + 10)
        .attr("y", (d) => y(d[props.yaxisValue]) + 20)
        
         
      barsvg.append("g").call(xAxis);
  
      barsvg.append("g").call(yAxis);

      //let noPercent = props.noPercent;
  
      function onMouseOver(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")       
        tooltip.select(".label").text(d.name);
        tooltip.select(".xaxis").text("Average Lag Time: " + d[props.xaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        tooltip.select(".yaxis").text("Number of Policies: " + d[props.yaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      }
  
      function onMouseOut() {
        tooltip.style("opacity", 0);
        // tooltip.style("z-index", "-1")
  
      }
  
      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        // tooltip.style("z-index", "9999")
        tooltip
        .style("top", (d3.event.clientY - 110) + "px")
        .style("left", (d3.event.clientX - 100) + "px");
      }
  
      // text label for the x axis
      var xaxisLocation = height - 30

      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr(
          "transform",
          "translate(" + ((width - margin.right) / 2) + " ," + xaxisLocation + ")"
        )
        .style("text-anchor", "middle")
        .text(props.xaxis);

  
      // text label for the y axis
        barsvg
          .append("text")
          .attr("class", "axislabel")
          .attr("transform", "rotate(-90)")
          .attr("y", 20)
          .attr("x", 0 - height / 3)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .text(props.yaxis);


        // Quadrant 1
        barsvg
          .append("text")
          .attr("class", "axislabel")
          .attr(
            "transform",
            "translate(" + ((width - margin.right) / 3) + " ," + (xaxisLocation - 75) + ")"
          )
          .style("text-anchor", "middle")
          .text("Earlier Adoption, Less Policies");

        // Quadrant 2
        barsvg
          .append("text")
          .attr("class", "axislabel")
          .attr(
            "transform",
            "translate(" + ((width - margin.right) / 3) + " ," + (30) + ")"
          )
          .style("text-anchor", "middle")
          .text("Earlier Adoption, More Policies");

         // Quadrant 3
         barsvg
         .append("text")
         .attr("class", "axislabel")
         .attr(
           "transform",
           "translate(" + ((width - margin.right) - 150) + " ," + (xaxisLocation - 75) + ")"
         )
         .style("text-anchor", "middle")
         .text("Later Adoption, Less Policies");

       // Quadrant 4
       barsvg
         .append("text")
         .attr("class", "axislabel")
         .attr(
           "transform",
           "translate(" + ((width - margin.right) - 150) + " ," + (30) + ")"
         )
         .style("text-anchor", "middle")
         .text("Later Adoption, More Policies");

    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedKeys])


  return (
    <div>
      <div
        ref={anchor}
        id={props.id}
        className={props.smalltext ? 'horizontal-graph' : 'graph1'}
        style={{ position: "relative", width: '900px' }}
      ></div>
    {props.legend ? (
      <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
      {keys.map((key, index) => {
          let color;
          if (index === 0){
            color = chartColorArray[0]
          } else if (index === 1) {
            color = chartColorArray[6]
          } else {
            color = chartColorArray[13]
          }
        return (
          <div
            key={index}
            className="field"
            style={{
              display: "flex",
              marginLeft: "20px",
              alignItems: "center",
            }}
          >
            {props.report ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  style={{
                    display: "flex",
                    width: "15px",
                    height: "15px",
                    backgroundColor: color,
                    border: "none",
                  }}
                ></button>
                <label
                  htmlFor={key}
                  style={{ fontSize: "12px", marginLeft: "5px" }}
                >
                  {key[0].toUpperCase() + key.slice(1)}
                </label>
              </div>
            ) : (
                <label
                    htmlFor={key}
                    className='chartCheckboxContainer'
                >
                     {key[0].toUpperCase() + key.slice(1)}
                    <input
                      type="checkbox"
                      className='chartCheckboxes'
                      checked={selectedKeys.includes(key)}
                      defaultChecked={keys.includes(key)}
                    />
                    <span
                      className="chartCheckmark"
                      onClick={(e) => handleLegendClick(e, key)}
                      style={{
                        backgroundColor: selectedKeys.includes(key) ? color : 'transparent',
                        borderColor: selectedKeys.includes(key) ? color : '#0000008a'
                      }}
                    />
                  </label>
              )}
          </div>
        );
      })}

      </div>
    ) : null}
    </div>
  );
}
