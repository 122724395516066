import React, { useEffect, useState } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";

export default function ScatterplotChart(props) {
  const anchor = React.createRef();
  const { chartColorArray } = useAppContext()
  //Only for graphs with legends
  const [keys, setKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(props.legendCategories ? props.legendCategories : []);



  const handleLegendClick = (e, key) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(selectedKeys.filter((_key) => _key !== key));
    } else {
      var newArray = Array.from(new Set([...selectedKeys, key]));
      setSelectedKeys(newArray);
    }
  };

  const specificPolicy = (item) => {
    let color;
    if (item === 'prevention') {
      color = chartColorArray[0]
    }
    if (item === 'protection') {
      color = chartColorArray[6]
    }
    if (item === 'prosecution') {
      color = chartColorArray[13]
    }
    return color;
  }


  useEffect(() => {
    var margin = { top: 20, right: 80, bottom: 90, left: 80 };
    var width = 900;
    var height = 750;

    if (props.report) {
      width = 1000;
    }

    const colors = d3.scaleOrdinal(chartColorArray)

    var xmax = 0;
    var ymax = 0;
    if (props.data) {
      var data = props.data;

      //Clickable Legend
      if (props.legendCategories) {
        setKeys(props.legendCategories)
        data = data.filter(item => selectedKeys.includes(item.type))
      }

      xmax = d3.max(data, (d) => +d[props.xaxisValue]) + 1;
      ymax = d3.max(data, (d) => +d[props.yaxisValue]);
      if (ymax <= 5) { 
        height = 550;
      }
      //Check if any objects have the same values (for hovers)
      const countMap = {};
      let sorting = data.map(obj => {
        const key = `${obj[props.xaxisValue]}-${obj[props.yaxisValue]}`;
        countMap[key] = (countMap[key] || 0) + 1;
        return obj
      });
      // eslint-disable-next-line array-callback-return
      const result = sorting.map(obj => {
        const key = `${obj[props.xaxisValue]}-${obj[props.yaxisValue]}`;
        if (countMap[key] > 1) {
          obj.size = 'larger'
          return obj
        } else {
          return obj
        }
      });
      console.log(result);

      // Select the graph
      const svg = d3.select(anchor.current);

      svg.selectAll("svg").remove();
      svg.selectAll(".tooltip").remove();

      var barsvg = svg
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(0, 0)`);

      var tooltip = d3
        .select(anchor.current)
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        // .style("z-index", "-1")
        .style("padding", "5px")
        .style("width", "200px");
      tooltip.append("div").attr("class", "label1");
      tooltip.append("div").attr("class", "xaxis1");
      tooltip.append("div").attr("class", "yaxis1");
      tooltip.append("div").attr("class", "label2");
      tooltip.append("div").attr("class", "xaxis2");
      tooltip.append("div").attr("class", "yaxis2");
      tooltip.append("div").attr("class", "label3");
      tooltip.append("div").attr("class", "xaxis3");
      tooltip.append("div").attr("class", "yaxis3");
  
      var x = d3
        .scaleLinear()
        .domain([0, xmax])
        .range([margin.left, width - margin.right])
      //.nice();

      var y = d3
        .scaleLinear()
        //.domain(d3.range(data.length))
        .domain([0, ymax])
        .range([height - margin.bottom, margin.top])

      var xAxisFormat;
      if (xmax < 20) {
        xAxisFormat = d3.axisBottom(x).ticks(xmax);
      } else {
        xAxisFormat = d3.axisBottom(x).tickSizeOuter(0);
      }

      var xAxis = (g) =>
        g
          .attr("transform", `translate(0,${height - margin.bottom})`)
          .call(xAxisFormat);

      //var yAxis = d3.axisLeft(y).ticks(10);
      var yAxis = (g) =>
        g
          .attr("transform", `translate(${80},0)`)
          .call(d3.axisLeft(y).ticks(null, data.y))
          .attr("class", "y-axis")
          .call((g) =>
            g
              .append("text")
              .attr("x", -margin.left)
              .attr("y", 10)
              .attr("text-anchor", "start")
              .text(data.y)
          );
    
  
    // If there are less than 5 states, change the plots and remove the labels
    if (ymax <= 5) { 
     
     barsvg
        .append("g")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("x", (d) => x(d[props.xaxisValue]))
        .attr("y", (d) => y(d[props.yaxisValue]))
        .attr("height", (d) => d.size ? 30: 10)
        .attr("width", (d) => d.size ? 30: 10)
        .attr("fill", (d, i) => {
          if (props.specificColors){
            let value = specificPolicy(d.type)
            return value
          } else {
            return colors(i);
          }
        })
      
      barsvg
        .append("g")
        .selectAll("text")
        .data(data)
        .join("text")
        .text((d) => d.size ? '> 1' : '')
        .attr("x", (d) => x(d[props.xaxisValue]) + 10)
        .attr("y", (d) => y(d[props.yaxisValue]) + 20)
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
      } else {
      // Main scatter plot points
      barsvg
        .append("g")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("x", (d) => x(d[props.xaxisValue]))
        .attr("y", (d) => y(d[props.yaxisValue]))
        .attr("height", 10)
        .attr("width", 10)
        .attr("fill", (d, i) => {
          if (props.specificColors) {
            let value = specificPolicy(d.type)
            return value
          } else {
            return colors(i);
          }
        })
      
      barsvg
        .append("g")
        .selectAll("text")
        .data(data)
        .join("text")
        .on("mouseover", onMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .text((d) => d.label)
        .attr("x", (d) => x(d[props.xaxisValue]) + 12)
        .attr("y", (d) => y(d[props.yaxisValue]) + 10)
      }   
         
      barsvg.append("g").call(xAxis);

      barsvg.append("g").call(yAxis);

      //let noPercent = props.noPercent;

      function onMouseOver(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999") 
        if (d.size) {
          let otherItems = data.filter(obj => obj[props.xaxisValue] ===  d[props.xaxisValue] && obj[props.yaxisValue] ===  d[props.yaxisValue])
          console.log(otherItems)
          for (let i = 1; i <= otherItems.length; i++){
            tooltip.select(`.label${i}`).text(otherItems[i-1].name).style("font-weight", "900");
            tooltip.select(`.xaxis${i}`).text("Average Years to Pass Policy: " + otherItems[i-1][props.xaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
            tooltip.select(`.yaxis${i}`).text("Number of States: " + otherItems[i-1][props.yaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
          }
        } else {
          tooltip.select(".label1").text(d.name);
          tooltip.select(".xaxis1").text("Average Years to Pass Policy: " + d[props.xaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
          tooltip.select(".yaxis1").text("Number of States: " + d[props.yaxisValue].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
        }    
      }

      function onMouseOut() {
        tooltip.style("opacity", 0);
        tooltip.style("z-index", "-1")
        tooltip.select(".label1").text("");
        tooltip.select(".xaxis1").text("");
        tooltip.select(".yaxis1").text("");
        tooltip.select(".label2").text("");
        tooltip.select(".xaxis2").text("");
        tooltip.select(".yaxis2").text("");
        tooltip.select(".label3").text("");
        tooltip.select(".xaxis3").text("");
        tooltip.select(".yaxis3").text("");
      }

      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", "9999")
        if (d.size) {
          tooltip
          .style("top", (d3.event.clientY - 200) + "px")
          .style("left", (d3.event.clientX - 100) + "px");
        } else {
          tooltip
          .style("top", (d3.event.clientY - 110) + "px")
          .style("left", (d3.event.clientX - 100) + "px");
        }  
      }

      // text label for the x axis
      var xaxisLocation = height - 30

      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr(
          "transform",
          "translate(" + ((width - margin.right) / 2) + " ," + xaxisLocation + ")"
        )
        .style("text-anchor", "middle")
        .text(props.xaxis);

      // text label for the y axis
      barsvg
        .append("text")
        .attr("class", "axislabel")
        .attr("transform", "rotate(-90)")
        .attr("y", 20)
        .attr("x", 0 - height / 3)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(props.yaxis);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, selectedKeys])


  return (
    <div>
      <div
        ref={anchor}
        id={props.id}
        className={props.smalltext ? 'horizontal-graph' : 'graph1'}
        style={{ position: "relative", width: '900px' }}
      ></div>
      {props.legend ? (
        <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
          {keys.map((key, index) => {
            let color;
            if (index === 0) {
              color = chartColorArray[0]
            } else if (index === 1) {
              color = chartColorArray[6]
            } else {
              color = chartColorArray[13]
            }
            return (
              <div
                key={index}
                className="field"
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  alignItems: "center",
                }}
              >
                <label
                  htmlFor={key}
                  className='chartCheckboxContainer'
                >
                  {key[0].toUpperCase() + key.slice(1)}
                  <input
                    type="checkbox"
                    className='chartCheckboxes'
                    checked={props.report ? false : selectedKeys.includes(key)}
                    onChange={(e) => console.log('')}
                  />
                  <span
                    className="chartCheckmark"
                    onClick={(e) => props.report ? console.log('') : handleLegendClick(e, key)}
                    style={{
                      backgroundColor: props.report || selectedKeys.includes(key) ? color : 'transparent',
                      borderColor: props.report || selectedKeys.includes(key) ? color : '#0000008a'
                    }}
                  />
                </label>
              </div>
            );
          })}

        </div>
      ) : null}
    </div>
  );
}
