import React, { useEffect, useRef } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import * as d3 from "d3";

export const VerticalMirrorBarChart = (props) => {
  const { chartColorArray } = useAppContext()
  const colors = d3.scaleOrdinal(chartColorArray)
  const ref = useRef(null);
  const cache = useRef(props.data);
  // const allKeys = props.keys.map((item) => {
  //   return item.variable;
  // });


  useEffect(() => {
    if (props.data) {
      const svg = d3.select(ref.current);
      svg.selectAll(".rect").remove();
      svg.selectAll(".group").remove();
      svg.selectAll(".axis").remove();
      svg.selectAll('.axisLabels').remove()

      const xaxisLines = svg.selectAll(".axis");
      xaxisLines.remove();

      const groupWithData = svg.selectAll(".rectangle-group");
      groupWithData.remove();

      let data = props.data.sort((a, b) => b.label < a.label ? -1 : 1);

      let margin = { top: 0, right: 50, bottom: 60, left: 60 };
      let rectHeight = 20;
      let dataNum = props.data.length + 1
      let width = props.report ? 700 : 900
      let height = (dataNum * rectHeight) + 50

      // if (props.report) {
      //   width = 550;
      // }

      let yScale = d3.scaleBand().range([height, 0]).domain(d3.range(0, dataNum));

      let xMax = d3.max(data, (d) => {
        if (Number(d[props.leftKeys.var]) > Number(d[props.rightKeys.var])) return Number(d[props.leftKeys.var]);
        return Number(d[props.rightKeys.var] + 10);
      });


      // wrapper around row with source label and bars
      let rectangleGroup = svg
        .selectAll(".rectangle-group")
        .data(data)
        .enter()
        // and group
        .append("g")
        .attr("class", "rectangle-group")
        .attr("transform", (d, idx) => {
          return `translate(${margin.left}, ${(yScale(idx + 1))})`;
        });


      // text for y axis bar labels
      let barLabelWidths = []

      rectangleGroup
        .append("text")
        .attr('class', 'barLabels')
        .text((d) => {
          return d[props.sourceVar];
        })
        .attr("dx", (d) => {
          barLabelWidths.push((d[props.sourceVar].length * 5) + 1);
          return (d[props.sourceVar].length * 5) + 1;
        }) // .attr("dx", rightScale(rightScale.domain()[1]))
        .attr("dy", +15)
        .style("text-anchor", "end")
        .style('font-size', '14px');

      let maxBarLabelWidth = d3.max(barLabelWidths)

      // rectangleGroup.selectAll('.barLabels').style('width', maxBarLabelWidth / 2)
      rectangleGroup.selectAll('.barLabels').attr("dx", maxBarLabelWidth + margin.left)


      let scaleForSetOfBars = ((width - maxBarLabelWidth - margin.left - margin.right) / 2) - 20 // minus 20 to add spaceing between y axis labels and x axis numbers

      let rightScale = d3
        .scaleLinear()
        .range([0, scaleForSetOfBars])
        .domain([0, xMax]);

      // eslint-disable-next-line no-unused-vars
      let rightAxis = d3.axisBottom().scale(rightScale).ticks(props.report ? 4 : 5); // 10

      let leftScale = d3
        .scaleLinear()
        .range([0, -scaleForSetOfBars])
        .domain([0, xMax]);

      let leftAxis = d3.axisBottom().scale(leftScale).ticks(props.report ? 4 : 5); // 10


      svg.enter();
      //.append("g")
      // .merge(svg)
      // svg.attr("transform", "translate(100, 75)");

      

      // let labels = svg.append("g").attr("class", "labels");

      // labels
      //   .append("text")
      //   .attr("transform", "translate(" + (width / 2 + 5) + "," + -25 + ")")
      //   .text(`${props.leftKeys.label} | ${props.rightKeys.label}`)
      //   .attr("text-anchor", "middle");

      let centerForXaxis = (maxBarLabelWidth + margin.left + margin.right) + scaleForSetOfBars + 20 // adding 20 for space between yaxis labels and x axis numbers
      let centerForBars = (maxBarLabelWidth + margin.left) + scaleForSetOfBars + 10 // adding 10 for space for x-axis

      svg
        .append("g")
        .attr("class", "x axis rightAxis")
        .attr("transform", `translate(${centerForXaxis} , ${height})`)
        .call(rightAxis);

      svg
        .append("g")
        .attr("class", "x axis leftAxis")
        .attr("transform", `translate(${centerForXaxis} , ${height})`)
        .call(leftAxis);


      let tooltip = d3
        .select(".mirrorBarChart")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0)
        .style("background-color", "white")
        .style("color", "black")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("position", "fixed")
        .style("z-index", "-1")
        .style("padding", "5px")
        .style("width", maxBarLabelWidth / 2); // '200px'
      tooltip.append("div").attr("class", "count");
      tooltip.append("div").attr("class", "label");
      tooltip.append("div").attr("class", "percent");


      // left side bars
      rectangleGroup
        .append("rect")
        .attr("class", "left noBorder")
        .on("mouseover", onLeftMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("height", 20) // yScale.bandwidth()
        .attr("width", (d) => {
          return rightScale(d[props.leftKeys.var]);
        })
        .attr("x", (d) => {
          return centerForBars - rightScale(d[props.leftKeys.var])
        })
        .attr("y", 0)
        .attr("fill", (d) => colors(props.leftKeys.var))
        .attr("stroke", "transparent !important")
        .attr("stroke-width", '0 !important');
      
      
      //right side bars
      rectangleGroup
        .append("rect")
        .attr("class", "right noBorder")
        .on("mouseover", onRightMouseOver)
        .on("mouseout", onMouseOut)
        .on("mousemove", onMouseMove)
        .attr("height", 20) // yScale.bandwidth()
        .attr("width", (d) => {
          return rightScale(d[props.rightKeys.var]);
        })
        .attr("x", centerForBars)
        .attr("y", 0)
        .attr("fill", (d) => colors(props.rightKeys.var))
        .attr("stroke", "transparent !important")
        .attr("stroke-width", '0 !important');


      // text label for the x axis title
      svg
        .append("text")
        .attr("class", "axisLabels")
        .attr(
          "transform",
          `translate(${centerForXaxis} , ${(height + margin.top + 50)})`
        )
        .style("text-anchor", "middle")
        .text(props.xaxis)
        .style('font-size', '14px');


      // text label for the y axis title
      svg
        .append("text")
        .attr("class", "axisLabels")
        .attr("transform", "rotate(-90)")
        .attr("y", '0')
        .attr("x", -(height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(props.yaxis)
        .style('font-size', '14px');

      
      function onLeftMouseOver(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", '9999');
        tooltip.select(".count").text(d[props.sourceVar]);
        tooltip.select(".label").text(`${props.keys[0].name}: ${d[props.leftKeys.var].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
        let total = d3.sum(
          data.map((d) => {
            return d[props.leftKeys.var];
          })
        );
        let percent;
        if (props.showPercent) {
          percent = Math.round((1000 * d[props.leftKeys.var]) / total) / 10;
          tooltip.select(".percent").text("Percent: " + percent + "%");
        }
      }


      function onRightMouseOver(d) {
        tooltip.style("opacity", 1);
        tooltip.select(".count").text(d[props.sourceVar]);
        tooltip.select(".label").text(`${ props.keys[1].name }: ${d[props.rightKeys.var].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`);
        let total = d3.sum(
          data.map((d) => {
            return d[props.rightKeys.var];
          })
        );
        let percent;
        if (props.showPercent) {
          percent = Math.round((1000 * d[props.rightKeys.var]) / total) / 10;
          tooltip.select(".percent").text("Percent: " + percent + "%");
        }
      }

      function onMouseOut() {
        tooltip.style("opacity", 0);
        tooltip.style("z-index", '-1');

      }

      function onMouseMove(d) {
        tooltip.style("opacity", 1);
        tooltip.style("z-index", '9999');
        tooltip
          .style("top", (d3.event.clientY - 150) + "px")
          .style("left", (d3.event.clientX - 200) + "px");
      }

      cache.current = props.data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <div id={props.id} className="mirrorBarChart">
      <svg width={'100%'} height={540}>
        <g ref={ref} transform={`translate(0,30)`} />
      </svg>

      <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
        {props.keys.map((key, i) => {
          return (
            <div
              key={key.name}
              className="field"
              style={{
                display: "flex",
                marginLeft: "20px",
                alignItems: "center",
              }}
            >
              <label
                htmlFor={key.name}
                className='chartCheckboxContainer cursorDefault'
              >
                {key.name}
                <input
                  type="checkbox"
                  className='chartCheckboxes'
                  checked={false}
                  onChange={(e) => console.log('')}
                />
                <span
                  className="chartCheckmark"
                  onClick={(e) => console.log('')}
                  style={{
                    backgroundColor: colors(key.variable),
                    borderColor: colors(key.variable)
                  }}
                />
              </label>
            </div>
          )
        })}
      </div>
    </div>
  );
};
