import React, { useRef, useEffect } from "react";
import { useAppContext } from '../../../../Lib/UserContext'
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function ServiceLegend(props) {
  const { chartColorArray } = useAppContext()
  const buttonRef = useRef(null)
  const popperRef = useRef(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [popperRefHeight, setPopperRefHeight] = React.useState(100)

  const handleLegendClick = (e, item) => {
    let legendArray = [...props.legendItems]
    let updateItemIndex = legendArray.indexOf(item)
    legendArray[updateItemIndex].checked = !legendArray[updateItemIndex].checked 
    props.handleLegendClick(legendArray)
  };
  let colors= {}
  props.legendItems.forEach((a, i) => colors[a.name] = chartColorArray[i])

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  useEffect(() => {
    if (props.report) {
      setAnchorEl(buttonRef.current)
      if (popperRef.current && popperRef.current.clientHeight) {

        setPopperRefHeight(popperRef.current.clientHeight)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.report, popperRef.current])

  return (
    <div className={props.report ? ' mapLegendWrapper reportMapLegendWrapper' : 'mapLegendWrapper'}
      style={props.report && { height: `calc(${popperRefHeight}px + 30px)` }}
    >
      <Button
        ref={buttonRef}
        aria-describedby={id}
        variant="contained"
        color="default"
        type="button"
        onClick={handleClick}
        className="filterButton legendButton"
      >
        <span>Legend</span>
        <ArrowDropDownIcon />
      </Button>
      <Popper
        ref={popperRef}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"top-start"}
        transition
        disablePortal // (the children will stay within their parent DOM hierarchy for pdf's to capture it)
        modifiers={props.report && {
          flip: {
            enabled: false,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div className={`maplegend ${props.report ? 'report' : ''}`}>
                <p style={{ margin: 0 }}>Services</p>

                <div>
                  {props.legendItems.map((item, i) => {
                    return (
                      <div key={i} className='field' style={{ display: "flex", alignItems: "center" }}>
                        <label
                          htmlFor={item.name}
                          className='chartCheckboxContainer'
                        >
                          {item.name}
                          <input
                            type="checkbox"
                            className='chartCheckboxes'
                            checked={props.report ? true : item.checked}
                            onChange={() => console.log('')}
                          />
                          <span
                            className="chartCheckmark"
                            onClick={(e) => {
                              if (props.report) {
                                console.log('')
                              } else {
                                handleLegendClick(e, item)
                              }
                            }}
                            style={{
                              backgroundColor: props.report ? colors[item.name] : (item.checked ? colors[item.name] : 'transparent'),
                              borderColor: colors[item.name]
                            }}
                          />
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
