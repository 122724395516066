import React, { useRef, useEffect } from "react";
import { useAppContext } from '../../../../Lib/UserContext'
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function FederalCasesLegend(props) {
    const {chartColorArray} = useAppContext()
    const buttonRef = useRef(null)
    const popperRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [popperRefHeight, setPopperRefHeight] = React.useState(100)
    const nationalCircuits = [
        {
            name: 'D.C. Circuit',
            color: chartColorArray[0]
        },
        {
            name: 'First Circuit',
            color: chartColorArray[1]
        },
        {
            name: 'Second Circuit',
            color: chartColorArray[2]
        },
        {
            name: 'Third Circuit',
            color: chartColorArray[3]
        },
        {
            name: 'Fourth Circuit',
            color: chartColorArray[4]
        },
        {
            name: 'Fifth Circuit',
            color: chartColorArray[5]
        },
        {
            name: 'Sixth Circuit',
            color: chartColorArray[6]
        },
        {
            name: 'Seventh Circuit',
            color: chartColorArray[7]
        },
        {
            name: 'Eight Circuit',
            color: chartColorArray[8]
        },
        {
            name: 'Ninth Circuit',
            color: chartColorArray[9]
        },
        {
            name: 'Tenth Circuit',
            color: chartColorArray[10]
        },
        {
            name: 'Eleventh Circuit',
            color: chartColorArray[11]
        },
    ]

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'transitions-popper' : undefined;
    let [locationType, setLocationType] = React.useState('Trafficking Locations')
    let [stateClickedData, setStateClickedData] = React.useState(false)
    

    useEffect(() => {
        if (props.report) {
            setAnchorEl(buttonRef.current)
            if (popperRef.current && popperRef.current.clientHeight) {

                setPopperRefHeight(popperRef.current.clientHeight)
            }
        }
        for (let i = 0; i < props.filters.length; i++) {
            if (props.filters[i].name === 'Location ' && props.filters[i].filter === 'Federal Cases') {
                setLocationType(props.filters[i].value[0]);
                setStateClickedData(props.filters[i]?.stateClickedData ? true : false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.report, props.filters, popperRef.current])

    return (
        <div className={`${!props.report ? 'mapLegendWrapper' : 'reportMapLegendWrapper'}`}
            style={props.report && { height: `calc(${popperRefHeight}px + 30px)` }}
        >
            <Button ref={buttonRef} aria-describedby={id} variant="contained" color="default" type="button" onClick={handleClick} className="filterButton legendButton">
                <span>Legend</span>
                <ArrowDropDownIcon />
            </Button>
            <Popper ref={popperRef} id={id} open={open} anchorEl={anchorEl} placement={'top-start'} transition
                disablePortal // (the children will stay within their parent DOM hierarchy for pdf's to capture it)
                modifiers={props.report && {
                    flip: {
                        enabled: false,
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            {locationType === "State Prosecuted" && !stateClickedData &&
                                <div className="maplegend">
                                <p>Cases Prosecuted</p>
                                    <div style={{ display: 'flex' }}>
                                        <div className='coloredDiv'></div>
                                        <div className='flexColumnEvenly'>
                                        <p>1</p>
                                        <p>20</p>
                                        <p>40</p>
                                        <p>50</p>
                                        <p>60</p>
                                        <p>100+</p>
                                        </div>
                                    </div>
                                </div>
                            }

                            {locationType === 'District Prosecuted' &&
                                <div className="maplegend">
                                {nationalCircuits.map((c, i) => (
                                    <div key={i} className='flexRowCenter'>
                                        <div className='colorBlock' style={{ backgroundColor: c.color }}></div>
                                        <p>{c.name}</p>
                                    </div>
                                ))}
                                </div>
                            }

                            {locationType === 'Trafficking Locations' || stateClickedData ?
                                <div className="maplegend">
                                    <p>Locations</p>
                                    <div style={{ display: 'flex' }}>
                                    <div
                                        className='coloredDiv fcdTraffLoc'></div>
                                        <div className='flexColumnEvenly'>
                                            <p>1</p>
                                            <p>2</p>
                                            <p>5</p>
                                            <p>10</p>
                                            <p>20</p>
                                            <p>30+</p>
                                        </div>
                                    </div>
                                </div>
                            : null}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    )
}