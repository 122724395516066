export const federalcasesdata = {
    traffTypes: [ 
        { 
            variable: "minorsextraf",
            name: "Minor Sex Trafficking"
        }, 
        {
            variable: "adultsextraf" ,
            name: "Adult Sex Trafficking"
        },  
        { 
            variable: "labor" ,
            name: "Labor Trafficking"
        },
        { 
            variable: "both" ,
            name: "Both Sex and Labor Trafficking"
        },  
    ],
    traffTypesCapitals: [
        {
            variable: "Minor Sex Trafficking",
            name: "Minor Sex Trafficking",
            
        },
        {
            variable: "Adult Sex Trafficking",
            name: "Adult Sex Trafficking",
            
        },
        {
            variable: "Labor Trafficking",
            name: "Labor Trafficking",
            
        },
        {
            variable: "Both Sex and Labor Trafficking",
            name: "Both Sex and Labor Trafficking",
            
        },
    ],
    raceEthnicity: [
        {
            variable: "black",
            name: "Black",
            
        },
        {
            variable: "hispanic",
            name: "Hispanic",
            
        },
        {
            variable: "other",
            name: "Other",
            
        },
        {
            variable: "unknown",
            name: "Unknown",
            
        },
        {
            variable: "white",
            name: "White",
            
        },
    ], 
    laborTraffType: [
        {
            variable: "Agriculture",
            name: "Agriculture",
            
        },
        {
            variable: "Arts/Sports/Entertainment",
            name: "Arts/Sports/Entertainment",
            
        },
        {
            variable: "Carnivals",
            name: "Carnivals",
            
        },
        {
            variable: "Commercial Cleaning Services",
            name: "Commercial Cleaning Services",
            
        },
        {
            variable: "Construction",
            name: "Construction",
            
        },
        {
            variable: "Domestic Servitude",
            name: "Domestic Servitude",
            
        },
        {
            variable: "Factories/Manufacturing",
            name: "Factories/Manufacturing",
            
        },
        {
            variable: "Forestry and Logging",
            name: "Forestry and Logging",
            
        },
        {
            variable: "Healthcare",
            name: "Healthcare",
            
        },
        {
            variable: "Health and Beauty",
            name: "Health and Beauty",
            

        },
        {
            variable: "Hotel and Hospitality",
            name: "Hotel and Hospitality",
            
        },
        {
            variable: "Illicit Activities",
            name: "Illicit Activities",
            
        },
        {
            variable: "Landscaping",
            name: "Landscaping",
            
        },
        {
            variable: "Peddling and Begging",
            name: "Peddling and Begging",
            
        },
        {
            variable: "Recreational Facilities",
            name: "Recreational Facilities",
            
        },
        {
            variable: "Restaurant and Food Services",
            name: "Restaurant and Food Services",
            
        },
        {
            variable: "Traveling Sales Crew",
            name: "Traveling Sales Crew",
            
        },
        {
            variable: "Other",
            name: "Other",
            
        },
        {
            variable: "Unknown",
            name: "Unknown",
            
        },
    ],
    defendantsAge1: [ 
        {
            variable: "24 and Under" ,
            name: "24 and Under",
            
        },  
        { 
            variable : "25-29" ,
            name: "25-29",
            
        },
        { 
            variable : "30-39" ,
            name: "30-39",
            
        },  
        {
            variable : "40-49" ,
            name: "40-49",
            
        },  {
            variable : "50 and Over" ,
            name: "50 and Over",
            
        }
    ], 
    defendantGender: [ 
        { 
            variable : "female" ,
            name: "Female",
            
        },
        { 
            variable : "male",
            name: "Male",
            
        }, 
        {
            variable : "unknown" ,
            name: "Unknown",
            
        }, 
    ], 
    defendantCountry: [ 
        { 
            variable : "total" ,
            name: "Total Individuals",
            
        },
    ],
    victimAge: [ 
        { 
            variable : "adult" ,
            name: "Adult",
            
        },
        { 
            variable : "minor",
            name: "Minor",
            
        }, 
        // {
        //     variable : "Unknown" ,
        //     name: "Unknown",
        //     
        // }, 
    ], 
    victimGender: [ 
        { 
            variable : "female" ,
            name: "Female",
            
        },
        { 
            variable : "male",
            name: "Male",
            
        }, 
        {
            variable : "unknown",
            name: "Unknown",
            
        }, 
    ],
    methodInternational: [
        {
            variable: "International",
            name: "International",
            
        },
        {
            variable: "National",
            name: "National",
            
        },
        {
            variable: "Unknown",
            name: "Unknown",
            
        }, 
    ],
    methodInterstate: [
        {
            variable: "Interstate",
            name: "Interstate",
            
        },
        {
            variable: "State",
            name: "State",
            
        }, 
    ],
}