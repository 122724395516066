import React, { useEffect, Fragment } from "react";
import { useAppContext } from "../../../../Lib/UserContext";
import SingleCard from "../../../../Components/Card";
import { StackedBarVariableXGraph } from "../../GraphComponents/StackedBarVariableXAxis";
import TableWithTotal from '../../../../Components/ReusableTables/TableWithTotal'
import TableForYearGraphs from '../../../../Components/ReusableTables/TableForYearGraphs'
import { GroupedBarGraph } from '../../../../Pages/Dashboard/GraphComponents/GroupedBarGraph'
import HorizontalBarChart from '../../../../Pages/Dashboard/GraphComponents/HorizontalBarChart'
// import AgeBarGraph from "./Graphs/AgeBarGraphV2";
// import AgeAllBarGraphV2 from "./Graphs/AgeAllBarGraphV2";
import AgeTable from "./Tables/AgeTable";
import AgeAllTable from "./Tables/AgeAllTable";
import AgeGenderTable from "./Tables/AgeGenderTable";
import AgeAllGenderTable from "./Tables/AgeAllGenderTable";
import RaceTable from "./Tables/RaceTable";
import RaceAgeTable from "./Tables/RaceAgeTable";
import HoverDonutChartTable from './Tables/HoverDonutChartTable'
import RaceAgeAllTable from "./Tables/RaceAgeAllTable";
import RaceGenderTable from "./Tables/RaceGenderTable";
//import IndicatorImpactTable from './Tables/IndicatorImpactTable';

//import GenderBarGraph from "./Graphs/GenderBarGraph";
//import RaceBarGraph from "./Graphs/RaceBarGraphV2";
import AgeGenderComp from "./Graphs/AgeGenderComp";
import AgeAllGenderComp from "./Graphs/AgeAllGenderComp";
import RaceGenderComp from "./Graphs/RaceGenderComp";
import RaceAgePie from "./Graphs/RaceAgePie";
import RaceAgeAllPie from "./Graphs/RaceAgeAllPie";
import "./Demographics.css";
import { makeStyles } from "@material-ui/core/styles";
import { data } from "./DemographicData";
// import { dendrogramData } from "./Graphs/DendrogramData";
import { raceagedata } from "./DemographicRaceAgeData";
// import { raceagealldata } from "./DemographicRaceAgeAllData";
// import GenderPieGraph from "./Graphs/GenderPieGraph";
//import RaceBubbleGraph from "./Graphs/RaceBubbleGraph";
// import AgeBubbleGraph from "./Graphs/AgeBubbleGraph";
// import AgeAllBubbleGraph from "./Graphs/AgeAllBubbleGraph";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { SingleBarGraph } from "../../GraphComponents/SingleBarGraph";
import DendrogramV2 from "./Graphs/DendrogramV2.js";
import TableHoverDonutChart from './Graphs/TableHoverDonutChart'
import HorizontalAndDonutChart from './Graphs/HorizontalAndDonutChart'
import InteractivePieChart from "./Graphs/InteractivePieChart.js";
import TotalIndicatorsTable from "./Tables/TotalIndicatorsTable.js";
import PieGraph from "../../GraphComponents/PieGraph"
import TotalIndicatorsOrderedTable from "./Tables/TotalIndicatorsOrderedTable";
import { BarChart } from "./Graphs/BarChart";
//import ScreeningsByConcernPie from './Graphs/ScreeningByConcernPie'
import ScreeningConcernTable from './Tables/ScreeningConcernTable'
import { screeningsgraphdata } from "../../GraphComponents/GraphVariables/ScreeningsData";
// import { indicatorImpactMockData } from '../../../../Data/indicatorImpactMockData';
// import { indicatorImpactMockDataMale } from '../../../../Data/indicatorImpactMockDataMale';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  cardContainer: {
    // width: "calc(100% - 200px)",
    // padding: "20px 10px",
    // marginTop: "80px",
  },
  openIcon: {
    color: "#28ADE3",
  },
  countyList: {
    padding: 0,
  },
  toolbar: {
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  content: {
    marginTop: "64px",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: 0,
  },
}));

export default function Screenings(props) {
  const classes = useStyles();
  const { stateLocation, chartColorArray } = useAppContext()
  const [graphData, setGraphData] = React.useState(data);
  const [defaultAges, setDefaultAges] = React.useState("0-24");
  const [defaultAgesGender, setDefaultAgesGender] = React.useState("0-24");
  const [defaultAgesRace, setDefaultAgesRace] = React.useState("0-24");
  const [defaultIndicatorImpact, setDefaultIndicatorImpact] = React.useState('females');

  const handleAgeToggle = (e, newAges) => {
    setDefaultAges(newAges);
  };

  const handleAgeGenderToggle = (e, newAges) => {
    setDefaultAgesGender(newAges);
  };

  const handleAgeRaceToggle = (e, newAges) => {
    setDefaultAgesRace(newAges);
  };

  const handleIndicatorImpactToggle = (e, newGender) => {
    setDefaultIndicatorImpact(newGender);
  };

  // var width;
  // var height;
  // var diameter;
  //if (window.innerWidth < 1920) {
  //width = 500;
  //height = 500;
  //diameter = 400;
  // } else {
  //width = 800;
  //height = 500;
  // diameter = 500;
  //}

  useEffect(() => {
    if (props.data.indicatorImpactMale) {
      setGraphData(props.data)
      console.log('props.data', props.data)
    } else {
      setGraphData(data)
    }
  }, [props.data])

  const renderAllCharts = () => {
    return (
      <div className={classes.root}>
        <div className={classes.cardContainer}>
          <div>
            <SingleCard
              // className={classes.card}
              width="100%"
              title="Screenings Information  >  All Screenings"
              id="Screenings Information All Screenings"
              graph={
                <StackedBarVariableXGraph
                  data={graphData.allScreenings}
                  xaxis="Year"
                  yaxis="Number of Screenings"
                  keys={screeningsgraphdata.allScreenings[stateLocation]}
                  trendLine={true}
                  trendLineLegend="Total Screenings"
                />
              }
              definitions={stateLocation === 'TX' ? [
                ["", "This chart shows total screenings by year based on the following values. The Time and Indicators filters do not apply to this chart."], ["WCCC", "Screenings submitted to the WestCoast Children's Clinic (primarily historic values, before Lighthouse was introduced)"], ["TJJD", "Screenings submitted by the Texas Juvenile Justice Departments"], ["DFPS", "Screenings submitted by the Texas Department of Family and Protective Services"], ["Lighthouse", "Screenings submitted in Lighthouse"]]
                :
                [
                  ["", "This chart shows total screenings by year based on the following values. The Time and Indicators filters do not apply to this chart."],
                  ["WCCC", "Screenings submitted to the WestCoast Children's Clinic (primarily historic values, before Lighthouse was introduced)"],
                  ["Lighthouse", "Screenings submitted in Lighthouse"]
                ]
              }
              table={
                <TableForYearGraphs
                  id='All Screenings'
                  data={graphData.allScreenings}
                  keys={screeningsgraphdata.allScreenings[stateLocation]}
                />
              }
              addToReports={props.addToReportPage}
            />
          </div>

          <div>
            <SingleCard
              // className={classes.card}
              width="100%"
              title="Screenings Information  >  Screenings and Individuals Screened"
              id="Screenings Information Screenings and Individuals Screened"
              graph={
                <GroupedBarGraph
                  keys={screeningsgraphdata.screeningsIndividuals}
                  data={graphData.screeningsIndividuals}
                  xaxis="Time (calendar year and quarter)"
                  yaxis="Number"
                  // yaxisMaxValue={true}
                  percentLine={true}
                  percentLegend="Percent (Individuals Screened/Screenings)"
                />
              }
              definitions={[["", "This chart compares total screenings and individuals screened, for all screenings submitted in Lighthouse. Screenings from other sources are not included, since they do not have information about screenings associated with a specific individual. The Time and Indicators filters do not apply to this chart."]]}
              table={<TableForYearGraphs
                id='Screenings and Individuals Screened'
                data={graphData.screeningsIndividuals}
                keys={screeningsgraphdata.screeningsIndividuals}
                noTotalRow
              />}
              addToReports={props.addToReportPage}
            />
          </div>

          <div>
            <SingleCard
              // className={classes.card}
              width="100%"
              title="Screenings Information  >  Screenings by Location"
              id="Screenings Information Screenings by Location"
              graph={
                <HorizontalBarChart
                  percentOfTotal={graphData.screeningsByLocation}
                  data={graphData.screeningsByLocation.slice(0, 10)} // get top 10 counties for the chart
                  value={'county'}
                  xaxis="Number of Screenings"
                  yaxis="Location"
                />
              }
              definitions={[["", "This chart shows the top ten locations for screenings in descending order, based on selected filters. The percent is the number of screenings by location out of all screenings. The table shows screenings for all locations. The Indicators filter does not apply to this chart."]]}
              table={
                <TableWithTotal
                  id="Screenings Information Screenings by Location"
                  name='Screenings by Location'
                  data={graphData.screeningsByLocation}
                  descending
                  headers={['Screenings by Location', 'Value']}
                  columnKeys={['county', 'total']}
                  totalKey='total'
                />
              }
              addToReports={props.addToReportPage}
            />
          </div>

          <div>
            <SingleCard
              // className={classes.card}
              width="100%"
              title="Screenings Information  >  Screenings by Location and Population"
              id="Screenings Information Screenings by Location and Population"
              graph={
                <HorizontalBarChart
                  percentOfTotal={graphData.screeningsByLocationPop}
                  data={graphData.screeningsByLocationPop.slice(0, 10)} // get top 10 counties for the chart
                  value="city"
                  xaxis="Number of Screenings per 100,000"
                  yaxis="Location"
                />
              }
              definitions={[["", "This chart shows the top ten locations for screenings per 100,000 population per location in descending order, based on selected filters. The percent is the number of screenings by location per 100,000 population out of all screenings per 100,000 population. The table shows screenings for all locations per 100,000 population. The Indicators filter does not apply to this chart."]]}
              table={
                <TableWithTotal
                  noTotalRow
                  id="Screenings Information Screenings by Location and Population"
                  name='Screenings by Location and Population'
                  data={graphData.screeningsByLocationPop}
                  descending
                  toFixed={2}
                  headers={['Screenings by Location and Population', 'Value']}
                  columnKeys={['city', 'total']}
                  totalKey='total'
                />
              }
              addToReports={props.addToReportPage}
            />
          </div>

          <div>
            <SingleCard
              // className={classes.card}

              width="100%"
              title="Screenings Information  >  Screenings by Concern Level"
              id="Screenings Information Screenings by Concern Level"
              graph={
                <PieGraph
                  data={graphData.screeningconcern}
                  keys={screeningsgraphdata.concernLevel}
                  sourceNameVar='calc_cseitscore'
                />
              }
              table={
                <ScreeningConcernTable data={graphData.screeningconcern} />
              }
              definitions={[["", "This chart shows screenings by concern level based on the selected filters. The Screenings and Indicators filters do not apply to this chart."]]}
              addToReports={props.addToReportPage}
            />
          </div>

          <div>
            <ToggleButtonGroup
              value={defaultAges}
              exclusive
              onChange={handleAgeToggle}
              aria-label="text formatting"
            >
              <ToggleButton value="0-24" aria-label="0-24">
                Ages 0 - 24
                  </ToggleButton>
              <ToggleButton value="ages25+" aria-label="ages25+">
                Ages 25+
                  </ToggleButton>
            </ToggleButtonGroup>
            {defaultAges === "0-24" ? (
              <SingleCard
                // className={classes.card}
                width="100%"
                id="Demographics Age"
                title="Demographics  >  Age"
                type="Screenings" //NOT SURE IF THIS IS CORRECT
                definitions={[["", "This chart displays the age of individuals at the time of screening, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators and Ages filters do not apply to this chart."]]}
                graph={
                  //<AgeBarGraph data={graphData.ageData} />
                  <SingleBarGraph
                    data={graphData.ageData}  //NOT SURE IF DATA SRC IS CORRECT
                    keys={screeningsgraphdata.ageData}
                    xaxis="Age"
                    graphVariable="age"
                    yaxis="Number of Screenings"
                    type="cc"
                    smalltext={true}
                  />
                }
                table={<AgeTable data={graphData.ageData} />}
                addToReports={props.addToReportPage}
              />

            ) : (
                // <div>
                <SingleCard
                  // className={classes.card}
                  width="100%"
                  id="Demographics Ages 25+"
                  title="Demographics  >  Ages 25+"
                  type="Screenings"
                  definitions={[["", "This chart displays the age of individuals at the time of screening, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators and Ages filters do not apply to this chart."]]}
                  graph={
                    //<AgeAllBarGraphV2 data={graphData.ageGroupData} />
                    <SingleBarGraph
                      data={graphData.ageGroupData}
                      keys={screeningsgraphdata.ageGroupData}
                      xaxis="Age Groups"
                      graphVariable="age"
                      yaxis="Number of Screenings"
                      type="cc"
                      smalltext={true}
                    />
                  }
                  table={<AgeAllTable data={graphData.ageGroupData} />}
                  addToReports={props.addToReportPage}
                />
              )}
          </div>

          {<SingleCard
            // className={classes.card}
            width="100%"
            id="Demographics Gender"
            title="Demographics  >  Gender"
            type="Screenings"
            definitions={[["", "This chart displays the gender of individuals at the time of screening, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators filter does not apply to this chart."]]}
            graph={
              //<GenderBarGraph data={graphData.genderData}
              <SingleBarGraph
                data={graphData.genderData}
                keys={screeningsgraphdata.genderData}
                xaxis="Gender"
                graphVariable="b_gender"
                yaxis="Number of Screenings"
                type="cc"
              //smalltext={true}
              />
            }
            table={
              <TableWithTotal
                data={graphData.genderData}
                headers={['Gender', 'Number of Screenings']}
                keys={screeningsgraphdata.genderData}
                columnKeys={['b_gender', 'value']}
                totalKey='value'
              />
            }
            addToReports={props.addToReportPage}
          />}

          {/* <SingleCard
                    // className={classes.card}
                    width="100%"
                    title="Demographics  >  Gender Pie Graph"
                    id="Demographics Gender Pie Graph"
                    graph={<GenderPieGraph
                      data={graphData.genderData}
                      keys={screeningsgraphdata.genderData}
                      sourceNameVar='b_gender'
                      width={400}
                      height={350}
                      innerRadius={0}
                      outerRadius={150}
                    />}
                    table={<TableWithTotal data={graphData.genderData} />}
                    addToReports={props.addToReportPage}
                  /> */}

          <SingleCard
            // className={classes.card}
            width="100%"
            id="Demographics Race"
            title="Demographics  >  Race"
            type="Screenings"
            definitions={stateLocation === 'TX' ? [['', 'From the start of CSE-IT screenings through 2022, Race and Ethnicity values were captured in a single demographic category for Race/Ethnicity, which included "Hispanic or Latino." For Lighthouse screenings starting in 2023, "Hispanic or Latino" is removed from Race and represented as Ethnicity to be consistent with census categories; all other values remain under Race. Screenings from the Texas Juvenile Justice Department (TJJD) and Department of Family and Protective Services (DFPS) continue to use a combined Race/Ethnicity value which includes "Hispanic or Latino." Starting in 2023, Race and Ethnicity are represented in separate charts, and "Hispanic or Latino" is no longer included with Race. Any values for "Hispanic or Latino" in Race/Ethnicity now appear as "Hispanic or Latino" in Ethnicity, any values for "Unknown" in Race/Ethnicity now appear as "Unknown" in Ethnicity, and all other values appear as "Not Hispanic or Latino" in Ethnicity. Values of five or less do not appear in the chart or table. The Indicators filter does not apply to this chart.']]
              :
              [['', 'From the start of CSE-IT screenings through 2022, Race and Ethnicity values were captured in a single demographic category for Race/Ethnicity, which included "Hispanic or Latino." For Lighthouse screenings starting in 2023, "Hispanic or Latino" is removed from Race and represented as Ethnicity to be consistent with census categories; all other values remain under Race. Starting in 2023, Race and Ethnicity are represented in separate charts, and "Hispanic or Latino" is no longer included with Race. Any values for "Hispanic or Latino" in Race/Ethnicity now appear as "Hispanic or Latino" in Ethnicity, any values for "Unknown" in Race/Ethnicity now appear as "Unknown" in Ethnicity, and all other values appear as "Not Hispanic or Latino" in Ethnicity. Values of five or less do not appear in the chart or table. The Indicators filter does not apply to this chart.']]}
            graph={
              //<RaceBarGraph data={graphData.raceData}
              <SingleBarGraph
                data={graphData.raceData}
                keys={screeningsgraphdata.raceData}
                xaxis="Race"
                graphVariable="b_race"
                yaxis="Number of Screenings"
                type="cc"
                smalltext={true}
              />
            }
            table={<RaceTable title='Race' data={graphData.raceData} />}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            // className={classes.card}
            width="100%"
            id="Demographics Ethnicity"
            title="Demographics  >  Ethnicity"
            type="Screenings"
            graph={
              <SingleBarGraph
                data={graphData?.ethnicityData ?? []}
                keys={screeningsgraphdata.ethnicityData}
                xaxis="Ethnicity"
                graphVariable="ethnicity"
                yaxis="Number of Screenings"
                type="cc"
                smalltext={true}
              />
            }
            table={
              <TableWithTotal
                title='Ethnicity'
                id='Ethnicity'
                name='Ethnicity'
                data={graphData?.ethnicityData}
                headers={['Ethnicity', 'Number of Screenings']}
                columnKeys={['ethnicity', 'value']}
                totalKey='value'
              />
            }
            definitions={stateLocation === 'TX' ? [['', 'From the start of CSE-IT screenings through 2022, Race and Ethnicity values were captured in a single demographic category for Race/Ethnicity, which included "Hispanic or Latino." For Lighthouse screenings starting in 2023, "Hispanic or Latino" is removed from Race and represented as Ethnicity to be consistent with census categories; all other values remain under Race. Screenings from the Texas Juvenile Justice Department (TJJD) and Department of Family and Protective Services (DFPS) continue to use a combined Race/Ethnicity value which includes "Hispanic or Latino." Starting in 2023, Race and Ethnicity are represented in separate charts, and "Hispanic or Latino" is no longer included with Race. Any values for "Hispanic or Latino" in Race/Ethnicity now appear as "Hispanic or Latino" in Ethnicity, any values for "Unknown" in Race/Ethnicity now appear as "Unknown" in Ethnicity, and all other values appear as "Not Hispanic or Latino" in Ethnicity. Values of five or less do not appear in the chart or table. The Indicators filter does not apply to this chart.']]
              :
              [["", 'From the start of CSE-IT screenings through 2022, Race and Ethnicity values were captured in a single demographic category for Race/Ethnicity, which included "Hispanic or Latino." For Lighthouse screenings starting in 2023, "Hispanic or Latino" is removed from Race and represented as Ethnicity to be consistent with census categories; all other values remain under Race. Starting in 2023, Race and Ethnicity are represented in separate charts, and "Hispanic or Latino" is no longer included with Race. Any values for "Hispanic or Latino" in Race/Ethnicity now appear as "Hispanic or Latino" in Ethnicity, any values for "Unknown" in Race/Ethnicity now appear as "Unknown" in Ethnicity, and all other values appear as "Not Hispanic or Latino" in Ethnicity. Values of five or less do not appear in the chart or table. The Indicators filter does not apply to this chart.']]}
            addToReports={props.addToReportPage}
          />

          {/* <SingleCard
                    // className={classes.card}
                    width="100%"
                    title="Demographics  >  Race/Ethnicity Bubble Graph"
                    id="Demographics Race/Ethnicity Bubble Graph"
                    graph={ <RaceBubbleGraph
                      data={graphData.raceData}
                      width={width}
                      height={height}
                      diameter={diameter}
                    />}
                    table={<RaceTable data={graphData.raceData} />}
                    addToReports={props.addToReportPage}
                  /> */}

          <div>
            <ToggleButtonGroup
              value={defaultAgesGender}
              exclusive
              onChange={handleAgeGenderToggle}
              aria-label="text formatting"
            >
              <ToggleButton value="0-24" aria-label="0-24">
                Ages 0 - 24
                  </ToggleButton>
              <ToggleButton value="ages25+" aria-label="ages25+">
                Ages 25+
                  </ToggleButton>
            </ToggleButtonGroup>
            {defaultAgesGender === "0-24" ? (

              <SingleCard
                // className={classes.card}
                width="100%"
                title="Demographics  >  Age + Gender"
                id="Demographics Age + Gender"
                definitions={[["", "This chart shows the number of screenings by age and gender, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators and Ages filters do not apply to this chart."]]}
                graph={
                  <AgeGenderComp data={graphData.ageGenderData} />
                }
                table={<AgeGenderTable data={graphData.ageGenderData} />}
                addToReports={props.addToReportPage}
              />

            ) : (

                <SingleCard
                  // className={classes.card}
                  width="100%"
                  title="Demographics  >  Ages 25+ + Gender"
                  id="Demographics Ages 25+ + Gender"
                  definitions={[["", "This chart shows the number of screenings by age and gender, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators and Ages filters do not apply to this chart."]]}
                  graph={
                    <AgeAllGenderComp
                      data={graphData.ageGenderGroupData}
                    />
                  }
                  table={<AgeAllGenderTable data={graphData.ageGenderGroupData} />}
                  addToReports={props.addToReportPage}
                />

              )}{" "}
          </div>
          <SingleCard
            // className={classes.card}
            width="100%"
            title="Demographics  >  Gender + Race"
            id="Demographics Gender + Race"
            definitions={[["", "This chart shows the number of screenings by gender and race, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators filter does not apply to this chart."]]}
            graph={<RaceGenderComp data={graphData.raceGenderData} />}
            table={<RaceGenderTable data={graphData.raceGenderData} />}
            addToReports={props.addToReportPage}
          />

          <div>
            <ToggleButtonGroup
              value={defaultAgesRace}
              exclusive
              onChange={handleAgeRaceToggle}
              aria-label="text formatting"
            >
              <ToggleButton value="0-24" aria-label="0-24">
                Ages 0 - 24
                  </ToggleButton>
              <ToggleButton value="ages25+" aria-label="ages25+">
                Ages 25+
                  </ToggleButton>
            </ToggleButtonGroup>
            {defaultAgesRace === "0-24" ? (
              <SingleCard
                // className={classes.card}
                width="calc(100vw - 475px)"
                title="Demographics  >  Age + Race"
                id="Demographics Age + Race"
                definitions={[["", "This chart shows the number of screenings by age and race, based on selected filters. Values of five or less do not appear in the chart or table. The Indicators and Ages filters do not apply to this chart."]]}
                graph={<RaceAgePie data={graphData.raceAgeData ?? raceagedata} />}
                table={<RaceAgeTable data={graphData.raceAgeData ?? raceagedata} />}
                addToReports={props.addToReportPage}
              />
            ) : (
                <SingleCard
                  // className={classes.card}
                  width="calc(100vw - 475px)"
                  title="Demographics  >  Ages 25+ and Race"
                  id="Demographics Ages 25+ and Race"
                  graph={<RaceAgeAllPie data={props.data.raceAllAgeData ?? []} />}
                  table={<RaceAgeAllTable data={props.data.raceAllAgeData ?? []} />}
                  addToReports={props.addToReportPage}
                />
              )}{" "}
          </div>

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Demographics  >  Sexual Orientation + Concern Level"
            id="Demographics Sexual Orientation + Concern Level"
            definitions={[["", "This chart shows number of screenings by sexual orientation and concern level. This chart only displays screenings collected in Lighthouse. Not Available represents screenings for which data on Sexual Orientation was not entered. The Indicators filter does not apply to this chart."]]}
            graph={
              <TableHoverDonutChart
                id="Demographics Sexual Orientation + Concern Level"
                data={graphData.orientationConcern}
                donutColorIndex={screeningsgraphdata.concernLevel}
                percentOf='Screenings'
                activeCategory={graphData?.orientationConcern && {
                  id: graphData.orientationConcern[0].id,
                  color: chartColorArray[0],
                  text: `Concern Level for ${graphData.orientationConcern[0].id}`
                }}
                style={{ overflow: "scroll" }}
              />
            }
            table={
              <HoverDonutChartTable
                id="Demographics Sexual Orientation + Concern Level"
                data={graphData.orientationConcern}
                columnHeaders={['Sexual Orientation', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
              />}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Demographics  >  Gender Identity + Concern Level"
            id="Demographics Gender Identity + Concern Level"
            graph={
              <TableHoverDonutChart
                id="Demographics Gender Identity + Concern Level"
                data={graphData.genderConcern}
                donutColorIndex={screeningsgraphdata.concernLevel}
                percentOf='Screenings'
                activeCategory={graphData?.genderConcern && {
                  id: graphData.genderConcern[0].id,
                  color: chartColorArray[0],
                  text: `Concern Level for ${graphData.genderConcern[0].id}`
                }}
                style={{ overflow: "scroll" }}
              />
            }
            table={
              <HoverDonutChartTable
                id="Demographics Gender Identity + Concern Level"
                data={graphData.genderConcern}
                columnHeaders={['Gender Identity', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
              />}
            definitions={[["", "This chart shows number of screenings by gender identity and concern level. This chart only displays screenings collected in Lighthouse. Not Available represents screenings for which data on gender identity was not entered. The Indicators filter does not apply to this chart."]]}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Demographics  >  Disability + Concern Level"
            id="Demographics Disability + Concern Level"
            graph={
              <TableHoverDonutChart
                id="Demographics Disability + Concern Level"
                data={graphData.disabilityConcern}
                donutColorIndex={screeningsgraphdata.concernLevel}
                percentOf='Screenings'
                activeCategory={graphData?.disabilityConcern && {
                  id: graphData.disabilityConcern[0].id,
                  color: chartColorArray[0],
                  text: `Concern Level for ${graphData.disabilityConcern[0].id}`
                }}
                style={{ overflow: "scroll" }}
              />
            }
            table={
              <HoverDonutChartTable
                id="Demographics Disability + Concern Level"
                data={graphData.disabilityConcern}
                columnHeaders={['Disability', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
              />}
            definitions={[["", "This chart shows number of screenings by disability type and concern level. This chart only displays screenings collected in Lighthouse. Not Available represents screenings for which data on Disability was not entered. The Indicators filter does not apply to this chart."]]}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Demographics  >  Education Level + Concern Level"
            id="Demographics Education Level + Concern Level"
            graph={
              <HorizontalAndDonutChart
                id="Demographics Education Level + Concern Level"
                data={graphData?.educationConcern}
                reverse
                yaxis='Education Level'
                xaxis='Number of Screenings'
                keys={screeningsgraphdata.educationConcern}
                donutColorIndex={screeningsgraphdata.concernLevel}
                percentOf='Screenings'
                activeCategory={{
                  id: graphData?.educationConcern?.[8]?.id ?? screeningsgraphdata.educationConcern[8].variable,
                  color: chartColorArray[8],
                  text: `Concern Level for ${screeningsgraphdata.educationConcern[8].name}`
                }}
                style={{ overflow: "scroll" }}
              />
            }
            table={
              <HoverDonutChartTable
                id="Demographics Education Level + Concern Level"
                data={graphData?.educationConcern}
                reverse
                columnHeaders={['Education Level', 'Number of Screenings', 'Number of Clear Concern', 'Number of Possible Concern', 'Number of No Concern']}
              />}
            definitions={[["", "This chart shows the number of screenings by highest education level attained and concern level. This chart only displays screenings collected in Lighthouse for which data on education level is available. The Indicators filter does not apply to this chart."]]}
            addToReports={props.addToReportPage}
          />

          <div className="services-border"></div>

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Indicators  >  All Indicators"
            id="Indicators All Indicators"
            definitions={[["", "This chart shows the number of times each indicator is scored at the concern level set in the Indicators filter (with a default of clear concern), based on selected filters. For example, if the Screenings and Indicators filters are both set to Clear Concern, the chart reflects the indicators or risk factors that are most prevalent for individuals who are screened as Clear Concern. All filters apply to this chart."]]}
            graph={
              <DendrogramV2
                width={1350}
                height={"2000"}
                style={{ overflow: "scroll" }}
                data={props.data.indicators ?? []}
              />
            }
            table={<TotalIndicatorsTable data={props.data.indicators ?? []} />}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Indicators  >  Indicators By Category"
            id="Indicators Indicators By Category"
            definitions={[["", "This chart shows the number of times each indicator is scored at the concern level set in the Indicators filter (with a default of clear concern), organized by CSE-IT category, based on selected filters. All filters apply to this chart."]]}
            graph={
              <InteractivePieChart
                width={1350}
                style={{ overflow: "scroll" }}
                data={props.data.indicators ?? []}
              />
            }
            table={<TotalIndicatorsTable data={props.data.indicators ?? []} />}
            addToReports={props.addToReportPage}
          />

          <SingleCard
            // className={classes.card}
            width="calc(100vw - 475px)"
            title="Indicators  >  Indicator Prevalence"
            id="Indicators Indicator Prevalence"
            definitions={[["", "This chart displays the prevalence of each indicator scored at the concern level set in the Indicators filter (with a default of clear concern), based on selected filters. All filters apply to this chart."]]}
            graph={
              <BarChart
                data={props.data.indicators ?? []}
                style={{ overflow: "scroll" }}
              />
            }
            table={
              <TotalIndicatorsOrderedTable
                width={1350}
                style={{ overflow: "scroll" }}
                data={props.data.indicators ?? []}
              />
            }
            addToReports={props.addToReportPage}
          />
          <div>
            <ToggleButtonGroup
              value={defaultIndicatorImpact}
              exclusive
              onChange={handleIndicatorImpactToggle}
              aria-label="text formatting"
            >
              <ToggleButton value="females" aria-label="females">
                Female
              </ToggleButton>
              <ToggleButton value="males" aria-label="males">
                Male
              </ToggleButton>
            </ToggleButtonGroup>
            {defaultIndicatorImpact === 'females' ? (
              <SingleCard
                width="calc(100vw - 475px)"
                title="Indicators  >  Indicator Relationship to CSE-IT Score (Clear Concern) - Female"
                id="Indicators Indicator Relationship to CSE-IT Score (Clear Concern)"
                graph={
                  <BarChart
                    data={props.data.indicatorImpactFemale ?? []}
                    style={{ overflow: 'scroll' }}
                    percent={true}
                  />
                }
                table={
                  <TotalIndicatorsOrderedTable
                    width={1350}
                    style={{ overflow: 'scroll' }}
                    data={props.data.indicatorImpactFemale ?? []}
                  />
                }
                definitions={[
                  [
                    '',
                    'The chart shows the percentage of Clear Concern screening conclusions for each CSE-IT indicator marked as Clear Concern, sorted from greatest to least. This chart is helpful for identifying indicators that could be meaningful to focus on during a screening, because the percent shows how often individuals with the Clear Concern indicator go on to have a Clear Concern screening overall. It is important to note that indicators with a high percentage do not necessarily cause the overall Clear Concern score.',
                  ],
                  [
                    '',
                    'Whereas the Indicator Prevalence chart shows how often an indicator is marked with the selected concern level in the collection of screenings, this Indicator Relationship to CSE-IT Score (Clear Concern) chart looks at each indicator to determine what percentage of screenings with that indicator marked as Clear Concern also have an overall score of Clear Concern. For instance, looking at a point in time snapshot for the 1a. Runaway indicator, there were 9139 screenings where that indicator was marked Clear Concern. Of those 9139 screenings, 4712 had an overall CSE-IT score of Clear Concern, resulting in a percentage of 51%. In other words, 51% of screenings with 1a. Runaway as Clear Concern had an overall score of Clear Concern.',
                  ],
                ]}
                addToReports={props.addToReportPage}
              />
            ) : (
                <SingleCard
                  // className={classes.card}
                  width="calc(100vw - 475px)"
                  title="Indicators  >  Indicator Relationship to CSE-IT Score (Clear Concern) - Male"
                  id="Indicators Indicator Relationship to CSE-IT Score (Clear Concern)"
                  graph={
                    <BarChart
                      data={props.data.indicatorImpactMale ?? []}
                      style={{ overflow: 'scroll' }}
                      percent={true}
                    />
                  }
                  table={
                    <TotalIndicatorsOrderedTable
                      width={1350}
                      style={{ overflow: 'scroll' }}
                      data={props.data.indicatorImpactMale ?? []}
                    />
                  }
                  definitions={[
                    [
                      '',
                      'The chart shows the percentage of Clear Concern screening conclusions for each CSE-IT indicator marked as Clear Concern, sorted from greatest to least. This chart is helpful for identifying indicators that could be meaningful to focus on during a screening, because the percent shows how often individuals with the Clear Concern indicator go on to have a Clear Concern screening overall. It is important to note that indicators with a high percentage do not necessarily cause the overall Clear Concern score.',
                    ],
                    [
                      '',
                      'Whereas the Indicator Prevalence chart shows how often an indicator is marked with the selected concern level in the collection of screenings, this Indicator Relationship to CSE-IT Score (Clear Concern) chart looks at each indicator to determine what percentage of screenings with that indicator marked as Clear Concern also have an overall score of Clear Concern. For instance, looking at a point in time snapshot for the 1a. Runaway indicator, there were 9139 screenings where that indicator was marked Clear Concern. Of those 9139 screenings, 4712 had an overall CSE-IT score of Clear Concern, resulting in a percentage of 51%. In other words, 51% of screenings with 1a. Runaway as Clear Concern had an overall score of Clear Concern.',
                    ],
                  ]}
                  addToReports={props.addToReportPage}
                />
              )}
          </div>

          {/* <div className="services-border"></div> */}

          {/* <div>
            <SingleCard
              // className={classes.card}

              width="100%"
              title="Screenings By Concern Level  >  All Screenings"
              id="Screenings By Concern Level All Screenings"
              graph={
                <PieGraph
                  data={graphData.screeningconcern}
                  // keys={screeningsgraphdata.screeningConcernData}
                  sourceNameVar=''
                />
              }
              // <ScreeningsByConcernPie
              //   data={graphData.screeningconcern}
              //   width={400}
              //   height={350}
              //   innerRadius={0}
              //   outerRadius={150}
              // />}
              table={<ScreeningConcernTable data={graphData.screeningconcern} />}
              addToReports={props.addToReportPage}
            /></div> */}
        </div>

      </div>
    );
  }

  const renderSingleChart = () => {
    const charts = renderAllCharts();
    const singleCards = charts.props.children.props.children;

    const index = singleCards.findIndex((card) => card.props.id === props.chartId);
    if (index <= -1) {
      return <Fragment></Fragment>;
    }

    const elementToRender = singleCards[index];
    return <SingleCard
        {...elementToRender.props}
        title={props.chartTitle || props.title}
        description={props.chartInsight || ""}
        shouldUseNewStyles={true}
    />;
  }

  return (
      props.chartId
        ? renderSingleChart()
        : renderAllCharts()
  );
}
