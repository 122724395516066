import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from '../../../Lib/UserContext'
import {
  select,
  scaleBand,
  event,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  line,
  //mouse,
  format,
} from "d3";
import * as d3 from 'd3';


export const StackedBarGraph = (props) => {
  //Setting keys and colors for graph from props
  const allKeys = props.keys.map((item) => {
    return item.variable;
  });
  const [keys, setKeys] = useState(allKeys);
  const { chartColorArray } = useAppContext()

  let colors = {};
  for (let i = 0; i < props.keys.length; i++) {
    colors[props.keys[i].variable] = chartColorArray[i];
  }

  //If a trendline, adding values to state
  const [trendLine, setTrendLine] = useState(true);
  const [trendLineLegend, setTrendLineLegend] = useState(true);

  const svgRef = useRef();
  const wrapperRef = useRef();

  const handleLegendClick = (e, key) => {
    if (keys.includes(key)) {
      setKeys(keys.filter((_key) => _key !== key));
    } else {
      var newArray = Array.from(new Set([...keys, key]));
      var sortedArray = [];
      for (let i = 0; i < allKeys.length; i++) {
        for (let j = 0; j < newArray.length; j++) {
          if (allKeys[i] === newArray[j]) {
            sortedArray.push(newArray[j]);
          }
        }
      }
      setKeys(sortedArray);
    }
  };

  // Click handler for trendline in legend
  const handleLegendLineClick = (e) => {
    // If chartLine is set to false in this component, update props
    props.updateChartLineShowing(!trendLine)
    setTrendLineLegend(!trendLineLegend);
    setTrendLine(!trendLine);
  };

  var data;
  if (props.data) {
    data = props.data;
  } else {
    data = [];
  }



  useEffect(() => {
    function endTooltip() {
      const boxes = document.querySelectorAll('.tooltip');

      boxes.forEach(box => {
        box.style.opacity = 0;
      });
    }
    window.addEventListener('scroll', endTooltip)
    //Selecting the container that will hold the D3 graph
    const svg = select(svgRef.current);

    //Removing the previous lines and data, in order to re-draw them when the filters are changed
    svg.selectAll(".line").remove();
    svg.selectAll(".myCircle").remove();
    svg.selectAll(".axis").remove();
    svg.selectAll(".text").remove();
    svg.selectAll(".quarterText").remove();
    svg.selectAll(".axislabel").remove();

    // Setting the height and width of the graph (responsive to viewport size)
    var margin = { top: 30, right: 0, bottom: 90, left: 80 };
    var width;
    var height;
    if (window.innerWidth > 1200 && window.innerWidth < 1440) {
      width = 900;
      height = 500;
    } else if (window.innerWidth >= 1440 && window.innerWidth < 1920) {
      width = 900;
      height = 500;
    } else {
      width = 1200;
      height = 500;
    }
    if (props.report) {
      width = 550;
    }

    //Organizing the data to created the stacked bars
    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);

    //Creating the hover tooltip
    var tooltip = select(wrapperRef.current)
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("color", "black")
      .style("border", "solid")
      .style("border-width", "2px")
      .style("border-radius", "5px")
      .style("position", "fixed")
      .style("z-index", "-1")
      .style("padding", "5px");
    tooltip.append("div").attr("class", "title");
    tooltip.append("div").attr("class", "label");
    tooltip.append("div").attr("class", "percent");

    // Creating all of the axis calculations
    const yMaxValue = max(data, (d) => d.total);

    const yScale = scaleLinear()
      .domain([0, yMaxValue])
      .range([height - margin.bottom, margin.top]);

    const x0Scale = scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width - margin.right]);

    const x1Scale = scaleBand()
      .domain(data.map((d) => d.type))
      .rangeRound([0, x0Scale.bandwidth()])
      .padding(0.12);

    const xAix = axisBottom(x0Scale);

    var ticks = yScale.ticks(),
      lastTick = ticks[ticks.length - 1],
      newLastTick = lastTick + (ticks[1] - ticks[0]);
    ticks.push(newLastTick);

    yScale.domain([0, newLastTick]);

    //Making sure that there are no decimal places in the y-axis
    var formatxAxis = format(".0f");
    var yAix;
    if (yMaxValue < 20) {
      yAix = axisLeft(yScale).ticks(yMaxValue).tickFormat(formatxAxis);
    } else {
      yAix = axisLeft(yScale).tickValues(ticks);
    }

    //Adding the x and y axis to the graph
    svg
      .select(".x-axis")
      .attr("transform", `translate(0, ${height - 75})`)
      .call(xAix)
      .selectAll("text")
      .call(wrap, x0Scale.bandwidth() - 5);

    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = 1, // parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width && line.length > 1) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em")
              .text(word);
          }
        }
      });
    }

    svg.select(".y-axis").attr("transform", `translate(${80}, 0 )`).call(yAix);

    // Adding the year/quarter lines on the x-axis
    if (data.length > 4) {
      let dividerDataArray = [];

      data.forEach(function (item) {
        dividerDataArray.push(item.name)
      });
      dividerDataArray = [...new Set(dividerDataArray)];

      let numOfBarsToCreate = dividerDataArray.length - 1 // minus 1 to not add a line to end on the right
      let yearLines = []

      for (let i = 0; i < numOfBarsToCreate; i++) {
        yearLines[i] = line()([
          [x0Scale.bandwidth() * (i + 1) + 80, height - margin.bottom],
          [x0Scale.bandwidth() * (i + 1) + 80, height - 30],
        ]);
      }
      for (let i = 0; i < yearLines.length; i++) {
        svg
          .append("path")
          .attr("fill", "none")
          .attr("stroke", "#E5E5E5 !important")
          .attr("stroke-width", 4)
          .attr("class", "line divider")
          .attr("d", yearLines[i]);
      }
    }


    let noPercent = props.noPercent;

    //Hover for bar graph
    function onMouseOver(d) {
      tooltip.style("opacity", 1);
      tooltip.style("z-index", "9999");

      var subgroupName = select(this.parentNode).datum().key;
      var label;
      for (let i = 0; i < props.keys.length; i++) {
        if (subgroupName === props.keys[i].variable) {
          label = props.keys[i].name;
        }
      }
      var tooltipData = select(this).data()[0];
      var total = tooltipData[1] - tooltipData[0];
      tooltip
        .select(".label")
        .text(
          "Total: " + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      var percent = Math.round((total / tooltipData.data.total) * 100);
      if (noPercent) {
        tooltip.select(".percent").text("");
      } else {
        tooltip.select(".percent").text("Percent: " + percent + "%");
      }
      tooltip.select(".title").text(label);
    }

    function onMouseOut() {
      tooltip.style("opacity", 0);
      tooltip.style("z-index", "-1");
    }

    function onMouseMove(d) {
      // tooltip.style("opacity", 1);
      // tooltip.style("z-index", "9999");

      tooltip
        .style("top", event.clientY - 150 + "px")
        .style("left", event.clientX - 200 + "px");
    }

    //This section creates the bars for the graph
    // eslint-disable-next-line
    svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr("fill", (layer) => colors[layer.key])
      .selectAll("rect")
      .data((layer) => layer)
      .join("rect")
      .on("mouseover", onMouseOver)
      .on("mouseout", onMouseOut)
      .on("mousemove", onMouseMove)
      .attr("x", (sequence) => {
        if (x1Scale.bandwidth() > 60) {
          return (
            x0Scale(sequence.data.name) +
            x1Scale(sequence.data.type) +
            x1Scale.bandwidth() / 2 -
            30
          );
        } else {
          return x0Scale(sequence.data.name) + x1Scale(sequence.data.type);
        }
      })
      .attr("width", x1Scale.bandwidth() > 60 ? 60 : x1Scale.bandwidth())
      .attr("y", (sequence) => yScale(sequence[1]))
      .attr("height", (sequence) => yScale(sequence[0]) - yScale(sequence[1]));

    //This section adds the quarter labels to graphs that display quarter data
    svg
      .selectAll(".text")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "quarterText")
      .attr(
        "x",
        (d) => x0Scale(d.name) + x1Scale(d.type) + x1Scale.bandwidth() / 2 - 10
      )
      .attr("y", height - 80)
      .style("font-size", ".8em")
      .text((d) => {
        if (d?.type.length === 2) {
          return d.type
        } else { return d.quarter }
      });


    // ********************* Start of Trend line *********************
    // Tberry added code here
    if (props.trendLine) {
      var lineData;
      if (props.line) {
        // eslint-disable-next-line
        lineData = props.line.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        });
      } else {
        // eslint-disable-next-line
        lineData = data.filter((item, key) => {
          if (item.total !== 0) {
            return item;
          }
        });
      }
      // if (props.trendLine) {
      //   // eslint-disable-next-line array-callback-return
      //   var lineData = data.filter((item, key) => {
      //     if (item.total !== 0) {
      //       return item;
      //     }
      //   });

      if (trendLine && lineData.length > 1) {
        //Adding line and circle hover
        const totalLine = line()
          .x((d) => x0Scale(d.name) + x1Scale(d.type) + x1Scale.bandwidth() / 2)
          .y((d) => yScale(d.total));

        svg
          .append("path")
          .datum(lineData)
          .attr("fill", "none")
          .attr("stroke", "#212121")
          .attr("stroke-width", 2)
          .attr("class", "line trendLine")
          .attr("d", totalLine);

        var Tooltip = select(wrapperRef.current)
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .style("background-color", "white")
          .style("color", "black")
          .style("border", "solid")
          .style("border-width", "2px")
          .style("border-radius", "5px")
          .style("position", "fixed")
          .style("z-index", "-1")
          .style("padding", "5px");

        // Tberry added code here
        let mouseover = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("opacity", 1);
          Tooltip.style("z-index", "9999");
        };
        let mousemove = function (d) {
          if (props.lineTitle) {
            Tooltip.html(
              props.lineTitle +
              ": " +
              d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            )
              .style("top", event.clientY - 75 + "px")
              .style("left", event.clientX - 150 + "px");
          } else {
            Tooltip.html(
              props.trendLineLegend +
              ": " +
              d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            )
              .style("top", event.clientY - 75 + "px")
              .style("left", event.clientX - 150 + "px");
          }
        };
        var mouseleave = function (d) {
          Tooltip.style("opacity", 0);
          Tooltip.style("z-index", "-1");
        };
        // var mouseover = function (d) {
        //   Tooltip.style("opacity", 1);
        // };
        // var mousemove = function (d) {
        //   Tooltip.html(
        //     props.trendLineLegend +
        //       ": " +
        //       d.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        //   )
        //     .style("top", event.clientY - 100 + "px")
        //     .style("left", event.clientX - 150 + "px");
        // };

        svg
          .append("g")
          .selectAll("dot")
          .data(lineData)
          .enter()
          .append("circle")
          .attr("class", "myCircle")
          .attr("cx", function (d) {
            return x0Scale(d.name) + x1Scale(d.type) + x1Scale.bandwidth() / 2;
          })
          .attr("cy", function (d) {
            return yScale(d.total);
          })
          .attr("r", 3)
          .attr("stroke", "#000000")
          .attr("stroke-width", 3)
          .attr("fill", "#000000")
          .on("mouseover", mouseover)
          .on("mousemove", mousemove)
          .on("mouseout", mouseleave);
      } else {
        svg.selectAll(".trendLine").remove();
        svg.selectAll(".dot").remove();
      }
    }

    // text label for the x axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr(
        "transform",
        "translate(" + (width + 60) / 2 + " ," + (height - 20) + ")"
      )
      .style("text-anchor", "middle")
      .text(props.xaxis);

    // text label for the y axis
    svg
      .append("text")
      .attr("class", "axislabel")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text(props.yaxis);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    colors,
    props.keys,
    keys,
    props.xaxis,
    props.yaxis,
    props.trendLine,
    props.trendLineLegend,
    trendLine,
    props.report,
  ]);

  return (
    <div className="graph" id={props.id} style={{ position: "relative" }}>
      <div ref={wrapperRef} style={{ position: "relative", height: 500 }}>
        <svg ref={svgRef} style={{ width: "100%", height: 500 }}>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
      <div className="fields" style={{ display: "flex", flexWrap: "wrap" }}>
        {allKeys.map((key) => {
          var labelId;
          for (let i = 0; i < props.keys.length; i++) {
            if (key === props.keys[i].variable) {
              labelId = props.keys[i].name;
            }
          }
          return (
            <div
              key={key}
              className="field"
              style={{
                display: "flex",
                marginLeft: "20px",
                alignItems: "center",
              }}
            >
              <label
                htmlFor={key}
                className='chartCheckboxContainer'
              >
                {labelId}
                <input
                  type="checkbox"
                  className='chartCheckboxes'
                  checked={props.report ? true : keys.includes(key)}
                  onChange={(e) => console.log('')}
                />
                <span
                  className="chartCheckmark"
                  onClick={(e) => props.report ? console.log('') : handleLegendClick(e, key)}
                  style={{
                    backgroundColor: props.report || keys.includes(key) ? colors[key] : 'transparent',
                    borderColor: props.report || keys.includes(key) ? colors[key] : '#0000008a'
                  }}
                />
              </label>
            </div>
          );
        })}
        {data.length > 1 && props.trendLine ? (
          <div
            className="field"
            style={{
              display: "flex",
              marginLeft: "20px",
              alignItems: "center",
            }}
          >
            <label
              className='chartCheckboxContainer'
            >
              {props.trendLineLegend}
              <input
                type="checkbox"
                className='chartCheckboxes'
                checked={props.report ? false : trendLineLegend}
                onChange={() => console.log('')}
              />
              <span
                className="chartCheckmark"
                onClick={(e) => props.report ? console.log('') : handleLegendLineClick(e)}
                style={{
                  backgroundColor: props.report || trendLineLegend ? 'black' : 'transparent',
                  borderColor: props.report || trendLineLegend ? 'black' : '#0000008a'
                }}
              />
            </label>
          </div>
        ) : null}
      </div>
    </div>
  );
};
